import React, { useRef, useState } from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import ContentTitle from '../components/ContentTitle';
import { useMessages } from '../context/MessageContext';

const AddSchoolMessage = () => {
    const [sidebarToggle, setSidebarToggle] = useState(true);
    const apiUrl = process.env.REACT_APP_API_MAIN_URL;
    const { showSuccess, showError, clearMessages } = useMessages();
    const [file, setFile] = useState(null);
    const fileRef = useRef(null);
    const [data, setData] = useState({
        title: '',
        description: ''
    });


    const handleChange = (event) => {
        const { name, value } = event.target;
        setData((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const sessionData = await JSON.parse(localStorage.getItem('student'));
        const language = localStorage.getItem('lang');
        const formData = new FormData();
        formData.append('school_id', sessionData?.school_id);
        formData.append('student_id', sessionData?.id);
        formData.append('subject', data?.title);
        formData.append('description', data?.title);
        formData.append('upload_file', file);
        formData.append('lang', language);

        const requestOptions = {
            method: "POST",
            body: formData
        }

        try {
            const response = await fetch(`${apiUrl}/addcontactmessage.json`, requestOptions);
            const result = await response.json();
            if (response.ok) {
                setData({
                    title: '',
                    description: ''
                })
                setFile(null);
                fileRef.current.value = ''
                showSuccess(`${result.message}`);
            } else {
                showError(result?.message)
            }
        } catch (error) {
            showError(error)
        } finally {
            setTimeout(() => {
                clearMessages()
            }, 2000)
        }
    };

    const goBack = () => {
        window.history.back();
    };

    const sidebarOpen = () => {
        setSidebarToggle(!sidebarToggle);
    }

    return (
        <div className='dashboard'>
            <Header click={sidebarOpen} />
            <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
            <div className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}>
                <ContentTitle name="Contact School" click={sidebarOpen} />
                <div className='card_board'>
                    <div className='content_card_title flex-view'>Add Message <button className='back-btn' onClick={goBack}>Back</button></div>
                    <div className='add-message-form-container'>
                        <form onSubmit={handleSubmit} encType="multipart/form-data">
                            <div className="form-group">
                                <label>Title*</label>
                                <input
                                    type="text"
                                    name="title"
                                    value={data?.title}
                                    placeholder="Enter Title"
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="form-group form-control">
                                <label>Attachment</label>
                                <input
                                    type="file"
                                    name="attachment"
                                    onChange={(e) => setFile(e.target.files[0])}
                                    ref={fileRef}
                                />
                            </div>

                            <div className="form-group">
                                <label>Description*</label>
                                <textarea name="description" value={data?.description} onChange={handleChange} />
                            </div>
                            <button type="submit" id="editstdntprfbtn" className="btn btn-primary send">
                                Send
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddSchoolMessage;
