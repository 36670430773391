import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import { useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { useMessages } from "../context/MessageContext";

const ViewQuiz = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const { examId, submitExamId } = useParams();
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_NODE_URL;
  const [getExam, setGetExam] = useState([]);
  const [textareaPara, setTextareaPara] = useState("");
  const { showSuccess, showError, clearMessages } = useMessages();
  const [answers, setAnswers] = useState({});
  const [quizes, setQuizes] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [examId]);

  // exam api questions
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const language = localStorage.getItem("lang");

      const requestOptions = {
        method: "GET",
      };
      try {
        const response = await fetch(
          `${apiUrl}/question/exam-questions?exam_id=${examId}&lang=${language}`,
          requestOptions
        );
        const result = await response.json();
        setGetExam(result);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [apiUrl, examId]);

  // set answers state
  const addAnsers = (event, index) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [`Q${index}:`]: event.target.value,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      const sessionData = await JSON.parse(localStorage.getItem("student"));
      setLoading(true);

      const language = localStorage.getItem("lang");

      const requestOptions = {
        method: "GET",
      };
      try {
        const response = await fetch(
          `${apiUrl}/quiz/getQuiz?id=${examId}&student_id=${sessionData?.id}&lang=${language}`,
          requestOptions
        );
        const result = await response.json();
        setQuizes(result[0].submit_exams[0].status);
        console.log("q", quizes);
      } catch (error) {
        console.error(error);
        setError(error.message || "Something went wrong");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [apiUrl]);

  // submit exam api
  const submitExam = async () => {
    setLoading(true);
    const sessionData = await JSON.parse(localStorage.getItem("student"));
    const language = localStorage.getItem('lang');

    const formData = new FormData();
    formData.append("upload_exams", [JSON.stringify(answers)]);
    formData.append("status", 1);
    formData.append("student_id", sessionData?.id);
    formData.append("exam_id", examId);
    formData.append('lang', language);
    const authToken = "vWEMNrj5R8aKwZYYZoxcUMDWk5OnHf";

    console.log(formData, "formData");

    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: formData,
    };
    try {
      const response = await fetch(
        `${apiUrl}/exam/submitExamUpdate/${submitExamId}`,
        requestOptions
      );
      const result = await response.json();
      showSuccess(result?.message);
      // window.location.reload();
      setAnswers({});
    } catch (error) {
      console.error(error);
      showError(error?.status + error.message);
    } finally {
      setLoading(false);
      setTimeout(() => {
        clearMessages();
      }, 3000);
    }
  };

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  console.log(answers, "answers");
  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="Exams Listing" click={sidebarOpen} />
        <div className="card_board">
          <div className="content_card_title flex-view">
            Exam
            <button className="back-btn" onClick={goBack}>
              Back
            </button>
          </div>
          {loading ? (
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#8e008e"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              wrapperClass=""
            />
          ) : (
            <>
              <div className="exam-header-container">
                <h4 style={{ color: "red" }}>Timer: Minutes</h4>
                <h4>
                  Exams (Deuxieme Trimestre) Subject: Religion Class: 4ème-A
                  (Primaire)
                </h4>
                <h4>
                  Maximum Marks: 100 Start Time: 13-08-2024 14:11 End Time:
                  31-08-2024 14:12
                </h4>
              </div>
              <div className="table-container">
                {getExam.map((val, index) => {
                  let optionsArray = [];
                  try {
                    // Convert the 'options' string to an array
                    optionsArray = JSON.parse(val.options) || [];
                  } catch (error) {
                    console.error("Error parsing options:", error);
                  }

                  return (
                    <div className="exam-container" key={index}>
                      <div className="question-container">
                        <h5>
                          Question {index + 1}. {val.question}
                        </h5>
                        <h5>({val.marks})</h5>
                      </div>
                      <div className="option-container">
                        {/* radio inputs */}
                        {val.ques_type === "objective" &&
                          optionsArray.length > 0 &&
                          optionsArray.map((option, i) => (
                            <div className="option-input-container" key={i}>
                              <input
                                type="radio"
                                name={`question-${index}`}
                                value={option}
                                onChange={(event) =>
                                  addAnsers(event, index + 1)
                                }
                              />
                              <label>{option}</label>
                            </div>
                          ))}
                        {/* textarea */}
                        {val.ques_type === "subjective" && (
                          <>
                            <textarea
                              maxLength={100}
                              onChange={(e) => {
                                setTextareaPara(e.target.value);
                                addAnsers(e, index + 1);
                              }}
                            />
                            <p
                              style={{
                                color: `${
                                  textareaPara.length < 100
                                    ? "rgb(161, 161, 161)"
                                    : "red"
                                }`,
                              }}
                            >
                              Total space count: {textareaPara.length} space.
                              Space left: {100 - textareaPara.length}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}

                <div className="submit-btn-container">
                  {quizes == 1 ? (
                    <p style={{ color: "red" }}>You have already submitted. </p>
                  ) : (
                    <button
                      type="submit"
                      id="editstdntprfbtn"
                      className="btn btn-primary submitExam-btn"
                      onClick={submitExam}
                      disabled={quizes == 1}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewQuiz;
