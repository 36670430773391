import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import CryptoJS from "crypto-js";
import { ThreeDots } from "react-loader-spinner";

const YouMeLive = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [youMeData, setYouMeData] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc"); // 'asc' for ascending, 'desc' for descending

  useEffect(() => {
    const getDRopboxView = async () => {
      setLoading(true);
      const studentData = await JSON.parse(localStorage.getItem("student"));
      const numberString = studentData?.id.toString();
      const md5Hash = CryptoJS.MD5(numberString).toString();
      const language = localStorage.getItem("lang");

      const formData = new FormData();
      formData.append("stid", md5Hash);
      formData.append("pid", "pid");
      formData.append("lang", language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };
      try {
        const response = await fetch(`${apiUrl}/umelive.json`, requestOptions);
        const result = await response.json();
        setYouMeData(result?.data?.subjects || []);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getDRopboxView();
  }, [apiUrl]);

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const goBack = () => {
    window.history.back();
  };

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const sortedData = [...youMeData].sort((a, b) => {
    if (sortOrder === "asc") {
      return a.name.localeCompare(b.name); // Ascending order
    }
    return b.name.localeCompare(a.name); // Descending order
  });

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="You-Me Live" click={sidebarOpen} />
        <div className="card_board">
          <div className="content_card_title flex-view">
            You-Me Live Classes
            <button className="back-btn" onClick={goBack}>
              Back
            </button>
          </div>
          <button className="sort-btn back-btn" onClick={toggleSortOrder}>
            Sort: {sortOrder === "asc" ? "Ascending" : "Descending"}
          </button>
          <div className="form-container">
            <div className="options">
              {loading ? (
                <ThreeDots
                  visible={true}
                  height="80"
                  width="80"
                  color="#8e008e"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              ) : (
                sortedData.map((val, index) => (
                  <Link
                    to={`/dashboard/you-me-live/class/${val?.id}`}
                    key={index}
                  >
                    <button className="option" data-target="study_abroad">
                      {val?.name}
                    </button>
                  </Link>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YouMeLive;
