import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Pagination from "../components/Pagination";
import "../css/style.css";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import { ThreeDots } from "react-loader-spinner";
import CryptoJS from "crypto-js";

const Grades = () => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const reactApiUrl = process.env.REACT_APP_API_MAIN_URL;
  const nodeApiUrl = process.env.REACT_APP_API_NODE_URL;
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [subjectFilter, setSubjectFilter] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [subjectsIDS, setSubjectsIDS] = useState([]);
  const [communityFilter, setCommunityFilter] = useState("");
  const [teacher, setTeacher] = useState("");
  const [teacherList, setTeacherList] = useState([]);
  const [gradeData, setGradeData] = useState([]);
  const [examTime, setExamTime] = useState("");
  const [originalData, setOriginalData] = useState([]);

  // teacher list api
  useEffect(() => {
    const getTeachers = async () => {
      const studentData = await JSON.parse(localStorage.getItem("student"));
      const language = localStorage.getItem("lang");

      const requestOptions = {
        method: "GET",
      };
      try {
        const response = await fetch(
          `${reactApiUrl}/teacherlist.json?class_id=${studentData?.class}&lang=${language}`,
          requestOptions
        );
        const result = await response.json();
        setTeacherList(result?.list); // Assuming your API returns an array of subjects
      } catch (error) {
        console.error(error);
      }
    };

    getTeachers();
  }, [reactApiUrl]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    setCurrentPage(1);
    filterAndMergeData(searchTerm);
  };

  const filterAndMergeData = (searchTerm) => {
    const { grade_details } = originalData;

    // Filter the data based on the search term
    const filteredData = grade_details.filter(
      (item) =>
        item.type?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.exam_period?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.subjects.subject_name
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.exam_type?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Merge the filtered data with the full dataset
    const updatedGradeData = originalData.grade_details.map((item) => {
      const matched = filteredData.find((f) => f.id === item.id);
      return matched || item;
    });
    console.log(updatedGradeData, originalData);
    setGradeData(updatedGradeData);
  };

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const handleSubjectChange = (event) => {
    setSubjectFilter(event.target.value);
  };

  const handleCommunityChange = (event) => {
    setCommunityFilter(event.target.value);
  };

  const handleTeacherChange = (event) => {
    setTeacher(event.target.value);
  };

  const handleExamTime = (event) => {
    setExamTime(event.target.value);
  };

  const submitList = async () => {
    setLoading(true);
    const studentData = await JSON.parse(localStorage.getItem("student"));
    const language = localStorage.getItem("lang");
    //  Convert number to string
    const numberString = studentData?.id.toString();
    // Create MD5 hash
    const md5Hash = CryptoJS.MD5(numberString).toString();
    const offset = (currentPage - 1) * limit;

    const formData = new FormData();
    formData.append("studentid", md5Hash);
    formData.append("offset", offset);
    formData.append("subjects", subjectFilter);
    formData.append("listteacher", teacher);
    formData.append("exams_ass", communityFilter);
    formData.append("exam_type", examTime);
    formData.append("lang", language);

    const requestOptions = {
      method: "POST",
      body: formData,
    };
    try {
      const response = await fetch(
        `${reactApiUrl}/gradesdata.json`,
        requestOptions
      );
      const result = await response.json();
      setSubjects(result?.subjectdata?.subject_name);
      setSubjectsIDS(result?.subjectdata?.id);
      setGradeData(result);
      setOriginalData(result);
      console.log(result);
      setTotalPages(Math.ceil(result.total_records / limit));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    submitList();
  }, []);

  // submit

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="All Grades" click={sidebarOpen} />
        <div className="card_board">
          <div className="content_card_title flex-view">
            Student Profile &gt; All Grades{" "}
            <button className="back-btn" onClick={goBack}>
              Back
            </button>
          </div>
          <div className="row clearfix" style={{ padding: "0px 20px" }}>
            <div className="col-lg-12">
              <div className="card">
                <div className="grade-selectboxes">
                  <select
                    className="form-control subj_s select2-hidden-accessible"
                    id="subjfilter"
                    onChange={handleSubjectChange}
                    value={subjectFilter}
                  >
                    <option value="">Choose Subject</option>
                    {subjects.map((subject, ind) => (
                      <option key={subjectsIDS[ind]} value={subjectsIDS[ind]}>
                        {subject}
                      </option>
                    ))}
                  </select>
                  <select
                    className="form-control community_filter select2-hidden-accessible"
                    id="comm_filter"
                    onChange={handleCommunityChange}
                    value={communityFilter}
                  >
                    {" "}
                    <option value="">Choose Value</option>
                    <option value="Exams">Exams</option>
                    <option value="Assessment">Assignment</option>
                    <option value="Quiz">Quiz</option>
                  </select>
                  {communityFilter === "Exams" && (
                    <select
                      className="form-control community_filter select2-hidden-accessible"
                      id="comm_filter"
                      onChange={handleExamTime}
                      value={examTime}
                    >
                      <option value="">Choose Value</option>
                      <option value="Monthly">Monthly</option>
                      <option value="Weekly">Weekly</option>
                      <option value="Yearly">Yearly</option>
                      <option value="Quartrely">Quartrely</option>
                      <option value="Half-yearly">Half-yearly</option>
                    </select>
                  )}
                  <select
                    className="form-control community_filter select2-hidden-accessible"
                    id="comm_filter"
                    onChange={handleTeacherChange}
                    value={teacher}
                  >
                    {" "}
                    <option value="">Choose Teacher</option>
                    {teacherList.map((teacherlist) => (
                      <option
                        key={teacherlist?.employee?.id}
                        value={teacherlist?.employee?.id}
                      >
                        {teacherlist?.employee?.f_name}{" "}
                        {teacherlist?.employee?.l_name}
                      </option>
                    ))}
                  </select>
                  <button className="back-btn" onClick={submitList}>Submit</button>
                </div>
                <div className="entries-and-search-container">
                  <div className="entries">
                    Show
                    <select
                      className="form-control"
                      value={limit}
                      onChange={handleLimitChange}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                    </select>
                    entries
                  </div>
                  <div className="entries">
                    Search:
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
                <div className="table-container">
                  <div className="table-responsive">
                    {loading ? (
                      <ThreeDots
                        visible={true}
                        height="80"
                        width="80"
                        color="#8e008e"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        wrapperClass=""
                      />
                    ) : (
                      // <p>Loading...</p>
                      <table
                        className="table table-hover js-basic-example dataTable table-custom table-striped m-b-0 c_list default_pageitem notification_table no-footer"
                        id="notification_table"
                        data-page-length="50"
                        role="grid"
                        aria-describedby="notification_table_info"
                      >
                        <thead className="thead-dark">
                          <tr role="row">
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Course Name: activate to sort column ascending"
                            >
                              Course Name
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Type: activate to sort column ascending"
                            >
                              Type
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Periode: activate to sort column ascending"
                            >
                              Periode
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Marks: activate to sort column ascending"
                            >
                              Marks
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Grades: activate to sort column ascending"
                            >
                              Grades
                            </th>
                          </tr>
                        </thead>
                        <tbody id="notificationbody">
                          {gradeData?.grade_details?.map((grade, index) => {
                            // console.log(gradeData?.subjectdata?.subject_name[index], "gradeeeeeeeeeeeeeeeeee")
                            return (
                              <>
                                <tr role="row" className="odd" key={grade?.id}>
                                  <td>{grade?.subjects.subject_name}</td>
                                  <td>
                                    {grade?.type} ({grade?.exam_type})
                                  </td>
                                  <td>{grade?.exam_period}</td>
                                  <td>{grade?.max_marks}</td>
                                  <td>{grade?.submit_exams?.grade}</td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
                <div className="pagination-container">
                  <p>
                    Show 1 to {limit} of {gradeData?.length} entries
                  </p>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Grades;
