import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { ThreeDots } from "react-loader-spinner";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../css/slider.css";

const CanteenSlider = ({ datass, children }) => {
  const [imageArray, setImageArray] = useState([]);

  useEffect(() => {
    const images = datass?.ci_details?.slider_banner_image.split(",");
    const imageMap = images?.map((e) => `${datass?.image_base}/${e}`);
    setImageArray(imageMap);
  }, [datass]);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  console.log(imageArray, "imageArray");
  return (
    <div className="slider-wrapper">
      {imageArray.length > 0 ? (
        <>
          <Slider {...settings}>
            {imageArray?.map((e, i) => {
              return <img key={i} src={e} alt={e} />;
            })}
          </Slider>
          {children}
        </>
      ) : (
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#8e008e"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
          wrapperClass=""
        />
      )}
    </div>
  );
};

export default CanteenSlider;
