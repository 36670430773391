import React, { useEffect, useState } from "react";
import "../css/style.css";
import Header from "../components/Header";
import { useMessages } from "../context/MessageContext";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import { Link, useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

const StateExamInnerPage = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [communityFilter, setCommunityFilter] = useState("newest");
  const [titleFilter, setTitleFilter] = useState("");
  const [dropbox, setDropbox] = useState([]);
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const { showSuccess, showError, clearMessages } = useMessages();
  const SERVERIMGURL = "https://you-me-globaleducation.org/school/img";
  const { name } = useParams();
  const { pageName } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [name]);

  const handleCommunityChange = (event) => {
    setCommunityFilter(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitleFilter(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const language = localStorage.getItem("lang");
      const formData = new FormData();
      formData.append("title", titleFilter);
      formData.append("filter", communityFilter);
      formData.append("lang", language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      const namereplace = name.replace("-", "");
      try {
        let response;
        if (name === "latinphilo_stateexam") {
          response = await fetch(
            `${apiUrl}/getLatinPhiloStateexam.json`,
            requestOptions
          );
        } else if (name === "mathphy_stateexam") {
          response = await fetch(
            `${apiUrl}/getMathPhysiqueStateexam.json`,
            requestOptions
          );
        } else if (name === "chembio_stateexam") {
          response = await fetch(
            `${apiUrl}/getChemBioStateexam.json`,
            requestOptions
          );
        } else if (name === "general_stateexam") {
          response = await fetch(
            `${apiUrl}/getGeneralStateexam.json`,
            requestOptions
          );
        } else if (name === "commerciale_stateexam") {
          response = await fetch(
            `${apiUrl}/getCommercialeStateexam.json`,
            requestOptions
          );
        } else {
          response = await fetch(
            `${apiUrl}/getTechniquesStateexam.json`,
            requestOptions
          );
        }

        const result = await response.json();
        setDropbox(result);
        showSuccess(result?.message);
      } catch (error) {
        console.error(error);
        showError(error);
      } finally {
        setLoading(false);
        setTimeout(() => {
          clearMessages();
        }, 3000);
      }
    };
    fetchData();
  }, [apiUrl, titleFilter, communityFilter]);

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="You-Me Academy" click={sidebarOpen} />
        <div className="card_board">
          <div className="content_card_title">
            State Exam &gt;{" "}
            {name === "latinphilo_stateexam"
              ? "Latin-Philo"
              : name === "latinphilo_stateexam"
              ? "Math-Physique"
              : name === "chembio_stateexam"
              ? "Chimie-Biologie"
              : name === "general_stateexam"
              ? "Pédagogie Générale"
              : name === "commerciale_stateexam"
              ? "Commerciale"
              : name === "techniques_stateexam"
              ? "Techniques et Autres"
              : ""}
            <div className="selectboxes">
              <select
                className="form-control community_filter select2-hidden-accessible"
                id="comm_filter"
                onChange={handleCommunityChange}
                value={communityFilter}
              >
                <option value="newest">Newest</option>
                <option value="pdf">PDF</option>
                <option value="video">Video</option>
                <option value="word">Word Document</option>
                <option value="audio">Audio</option>
              </select>
              <button className="back-btn" onClick={goBack}>
                Back
              </button>
            </div>
          </div>
          <div className="form-container">
            <div className="drobbox-body">
              <select
                className="form-control js-states chosetitle select2-hidden-accessible"
                id="title_filter"
                onChange={handleTitleChange}
                value={titleFilter}
              >
                <option value="">Choose Title</option>
                {dropbox?.title_details?.map((t) => (
                  <option key={t?.max_id} value={t?.max_id}>
                    {t?.title}
                  </option>
                ))}
              </select>

              {loading ? (
                <ThreeDots
                  visible={true}
                  height="80"
                  width="80"
                  color="#8e008e"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  wrapperClass=""
                />
              ) : (
                // <p>Loading...</p>
                <div className="data-body">
                  <div className="data-body-row" id="viewcommunity">
                    {dropbox?.know_details?.map((drop) => {
                      return (
                        <div
                          className="col-sm-2 col_img"
                          key={drop.id}
                          style={{ marginBottom: "20px" }}
                        >
                          <Link
                            to={`/dashboard/state-exam/${name}/${drop.id}`}
                            className="viewknow"
                          >
                            {drop.image != "" ? (
                              <img
                                src={`${SERVERIMGURL}/${drop?.image}`}
                                alt="Dropbox Icon"
                              />
                            ) : (
                              <img
                                src="https://you-me-globaleducation.org/youme-logo.png"
                                alt="Dropbox Icon"
                              />
                            )}
                            <div className="set_icon">
                              <i className="bi bi-filetype-pdf"></i>
                            </div>
                          </Link>
                          <p
                            className="title"
                            style={{ color: "#000", margin: "0" }}
                          >
                            <b>Titre</b>: {drop?.title} <br />
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {/* {!dropbox?.length ? <h3 style={{ color: "gray", margin: "0" }}>Data Not Found</h3> : ""} */}
              <form
                method="post"
                acceptCharset="utf-8"
                action="/school/Dropbox/"
                style={{ display: "none" }}
              >
                <div>
                  <input type="hidden" name="_method" value="POST" />
                  <input
                    type="hidden"
                    name="_csrfToken"
                    autoComplete="off"
                    value="fd82b978dfb069d344e73b0d4e9dca020ee9d23fbca43988689186f03e216fc58422d41d5d040c943c9761c1bcb0be38cb915d43025ce94bebffabf8685a6482"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StateExamInnerPage;
