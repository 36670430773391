import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";

const StudentDiary = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [todyDate, setTodayDate] = useState("");
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [subjects, setSubjects] = useState([]);
  const [subjectTextIDs, setSubjectTextIDs] = useState([]);
  const [subjectContent, setSubjContent] = useState([]);
  const [note, setNote] = useState(null);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const textref = useRef("");
  const todayRef = useRef();

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setIsSaveButtonEnabled(todyDate === today);
  }, [todyDate]);

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setTodayDate(formattedDate);
  }, []);

  // student diary first time visit
  const fetchData = async () => {
    const sessionData = await JSON.parse(localStorage.getItem("student"));
    const language = localStorage.getItem("lang");
    const formData = new FormData();
    formData.append("class_id", sessionData?.class);
    formData.append("school_id", sessionData?.school_id);
    formData.append("session_id", sessionData?.session_id);
    formData.append("student_id", sessionData?.id);
    formData.append("lang", language);

    const requestOptions = {
      method: "POST",
      body: formData,
    };

    try {
      const REACT_APP_API_MAIN_URL =
        "https://you-me-globaleducation.org/school/api";

      // Fetch first API
      const subject = await fetch(
        `${REACT_APP_API_MAIN_URL}/subjectlist.json`,
        requestOptions
      );
      const subject1 = await subject.json();

      // Fetch second API
      const response = await fetch(
        `${apiUrl}/studentdairy.json`,
        requestOptions
      );
      const result = await response.json();

      // Filter out unwanted entries
      const filterSub = result.dairydtl.filter((e) => e.note !== "dairy_note");

      // Merge subjects based on matching subject_id
      const mergedSubjects = subject1.subjects.map((subj) => {
        const matchedDiary = filterSub.find(
          (diary) => diary.subject_id === subj.id
        );
        return {
          ...subj,
          subject_content: matchedDiary ? matchedDiary.subject_content : "", // Set content if found
        };
      });

      setSubjects(mergedSubjects);
    } catch (error) {
      console.log(error);
      setError(error.message || "An unexpected error occurred.");
    }
  };

  useEffect(() => {
    fetchData();
  }, [apiUrl]);
  console.log("ss", subjects);
  // on datewise data student diary
  const handliClick = async () => {
    const sessionData = await JSON.parse(localStorage.getItem("student"));
    const language = localStorage.getItem("lang");
    const convertDateFormat = () => {
      const date = new Date(todyDate);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() is zero-based
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    };

    // Usage
    const formattedDate = convertDateFormat(todyDate);
    const formData = new FormData();
    formData.append("class_id", sessionData?.class);
    formData.append("school_id", sessionData?.school_id);
    formData.append("session_id", sessionData?.session_id);
    formData.append("student_id", sessionData?.id);
    formData.append("enddate", formattedDate);
    formData.append("lang", language);

    const requestOptions = {
      method: "POST",
      body: formData,
    };

    const REACT_APP_API_MAIN_URL =
      "https://you-me-globaleducation.org/school/api";

    // Fetch first API
    const subject = await fetch(
      `${REACT_APP_API_MAIN_URL}/subjectlist.json`,
      requestOptions
    );
    const subject1 = await subject.json();

    try {
      const response = await fetch(
        `${apiUrl}/studentdairy.json`,
        requestOptions
      );
      const result = await response.json();

      // Filter out unwanted entries
      const filterSub = result.dairydtl.filter((e) => e.note !== "dairy_note");

      // Merge subjects based on matching subject_id
      const mergedSubjects = subject1.subjects.map((subj) => {
        const matchedDiary = filterSub.find(
          (diary) => diary.subject_id === subj.id
        );
        return {
          ...subj,
          subject_content: matchedDiary ? matchedDiary.subject_content : "", // Set content if found
        };
      });

      setSubjects(mergedSubjects);
    } catch (error) {
      console.log(error);
      setError(error.message || "An unexpected error occurred.");
    }
  };

  const handleTextChange = (index, value) => {
    const updatedSubjects = subjects.map((subject, idx) =>
      subject.id === index ? { ...subject, subject_content: value } : subject
    );
    setSubjects(updatedSubjects);
    setSubjContent((prev) => ({
      ...prev,
      [index]: value,
    }));

    if (!subjectTextIDs.includes(index)) {
      setSubjectTextIDs((previous) => [...previous, index]);
    }
    if (value === "") {
      const filterItem = subjectTextIDs.filter((e) => {
        return e !== index;
      });
      setSubjectTextIDs(filterItem);
      setSubjContent((prev) => {
        const { [index]: removed, ...rest } = prev;
        return rest;
      });
    }
  };

  const handleSave = async () => {
    const sessionData = await JSON.parse(localStorage.getItem("student"));
    const language = localStorage.getItem("lang");
    const convertDateFormat = () => {
      const date = new Date(todyDate);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() is zero-based
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    };

    // Usage
    const formattedDate = convertDateFormat(todyDate);
    const rawData = {
      class_id: sessionData?.class,
      school_id: sessionData?.school_id,
      session_id: sessionData?.session_id,
      studentid: sessionData?.id,
      subject_id: subjectTextIDs, // Already an array, no need to JSON.stringify
      datedairy: formattedDate,
      dairy_note: note,
      note: note,
      subject_content: subjectContent, // Already an array/object, no need to JSON.stringify
      lang: language,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Specify you're sending raw JSON
      },
      body: JSON.stringify(rawData),
    };

    try {
      const response = await fetch(
        `${apiUrl}/adddairydetail.json`,
        requestOptions
      );
      const result = await response.json();
      if (result?.status == 200) {
        setSuccess(result?.Message || result?.message);
        textref.current.value = "";
        setNote(null);
        setSubjectTextIDs([]);
        setSubjContent([]);
        fetchData();
      } else {
        setError(result?.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setTimeout(() => {
        setSuccess("");
      }, 3000);
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <div id="main-content1" className="content_container">
          <ContentTitle
            name="Student Dairy"
            click={sidebarOpen}
            sidebarToggle={sidebarToggle}
          />
          <div className="card_board student-diary-card_board">
            <div className="content_card_title flex-view">
              Student Dairy
              <button className="back-btn" onClick={goBack}>
                Back
              </button>
            </div>
            <div className="row clearfix" style={{ padding: "0px 20px" }}>
              <div className="col-lg-12">
                <div className="card">
                  <div className="date-time-input-wrapper">
                    <div className="input-container">
                      <label>Select Date*</label>
                      <input
                        ref={todayRef}
                        type="date"
                        value={todyDate}
                        onChange={(e) => setTodayDate(e.target.value)}
                      />
                    </div>
                    <button className="back-btn" onClick={handliClick}>
                      Submit
                    </button>
                  </div>
                  {/* student diary form wrapper */}
                  <div className="student-diary-form-wrapper">
                    <div className="title-and-date-row">
                      <h4>Student Diary</h4>
                      <h4>Date: {todyDate} </h4>
                    </div>
                    {subjects?.map((val) => (
                      <div
                        className="student-diary-container"
                        key={val.id || Math.random()}
                      >
                        <div className="student-diary-subject-container">
                          <p>{val?.subject_name}:</p>
                        </div>
                        <div className="student-diary-form-container">
                          <textarea
                            disabled={!isSaveButtonEnabled}
                            ref={textref}
                            placeholder="For Students..."
                            style={{ marginBottom: "8px" }}
                            value={val.subject_content ?? ""} // ✅ Ensure it's never null
                            onChange={(e) =>
                              handleTextChange(val?.id, e.target.value)
                            }
                          />

                          <textarea placeholder="For Teachers..." disabled />
                        </div>
                      </div>
                    ))}

                    <div className="student-diary-container">
                      <div className="student-diary-subject-container">
                        <p>Dairy note:</p>
                      </div>
                      <div className="student-diary-form-container">
                        <textarea
                          className="note"
                          disabled={true}
                          style={{ height: "100px" }}
                          value={note}
                          onChange={(e) => setNote(e.target.value)}
                        />
                      </div>
                    </div>
                    {success !== "" && (
                      <p
                        style={{
                          width: "100%",
                          padding: "10px 20px",
                          boxSizing: "border-box",
                          color: "white",
                          backgroundColor: "green",
                        }}
                      >
                        {success}
                      </p>
                    )}

                    {error !== "" && (
                      <p
                        style={{
                          width: "100%",
                          padding: "10px 20px",
                          boxSizing: "border-box",
                          color: "white",
                          backgroundColor: "red",
                        }}
                      >
                        {error}
                      </p>
                    )}

                    {isSaveButtonEnabled && (
                      <div className="btn-container-save">
                        <button
                          className="back-btn"
                          style={{ marginTop: "20px" }}
                          onClick={handleSave} // Disable if date is not today's date
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDiary;
