// PieChart.js
import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

// Register the required components
Chart.register(ArcElement, Tooltip, Legend);

const PieChart = ({ due, paid }) => {
  // Data for the chart
  const data = {
    labels: ['Paid', 'Due'],
    datasets: [
      {
        data: [paid, due],
        backgroundColor: ['green', 'red'], // Colors for each section
        hoverBackgroundColor: ['green', 'red']
      }
    ]
  };

  // Options for the chart
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Payment Status',
      },
    },
  };

  return <Pie data={data} options={options} />;
};

export default PieChart;
