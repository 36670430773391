// src/admin/Header.js
import React, { useState } from 'react';
import '../css/style.css'; // Import your CSS file
import logo from '../images/logo.png';
import schoolLogo from '../images/schoolLogo.png';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import profileImg from '../images/profileModal.png';

const Header = ({click}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const sessionData = JSON.parse(localStorage.getItem('student'));

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="header-container">
      <div className="header">
        <div className='hamburger-container' onClick={click}><i className="bi bi-list"></i></div>
        <div className="logo">
          <img src='https://you-me-globaleducation.org/you-meheaderlogo.png' alt="Logo" />
        </div>
        <div className="profile-img">
          <img src={schoolLogo} alt="School Logo" />
          <span>Nancy School - 25 May</span>
        </div>
        <div className='header-profile-container'>
          <div className='header-profile-image-container'>
            <img src={profileImg}/>
          </div>
          <div className="profile">
            <button onClick={toggleDropdown} className="profile-btn">
              {sessionData?.f_name} {sessionData?.l_name} <i className="bi bi-caret-down-fill"></i>
            </button>
            <ul className={`profile-menu ${dropdownOpen ? "dropdown-transition" : "dropdown-transition-false"}`}>
              <li className='profile-btn-section'>
                <Link to="/dashboard/student-view-profile">
                  <i className="bi bi-person-circle"></i>
                  My Profile
                </Link>
              </li>
              <li onClick={() => logout(navigate)}><i className="bi bi-box-arrow-right"></i> Logout</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
