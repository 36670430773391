import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../components/Header";
import Pagination from "../components/Pagination";
import "../css/style.css";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";

const YouMeLiveClassView = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const { id } = useParams();
  const [originalData, setOriginalData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const sessionData = await JSON.parse(localStorage.getItem("student"));
      const language = localStorage.getItem("lang");
      const offset = (currentPage - 1) * limit;
      const formData = new FormData();
      formData.append("classid", sessionData?.class);
      formData.append("subjectid", id);
      formData.append("lang", language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };
      try {
        const response = await fetch(
          `${apiUrl}/unlivelinks.json`,
          requestOptions
        );
        const result = await response.json();
        setNotifications(result?.data);
        setOriginalData(result?.data);
        console.log(result.data);
        setTotalPages(Math.ceil(result.total_records / limit));
      } catch (error) {
        setError(error.message || "Something went wrong");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [apiUrl, currentPage, limit]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    setCurrentPage(1);

    // Make sure originalData is an array
    const filteredData = originalData.flatMap((item) => {
      // Check if item.links is an array
      if (Array.isArray(item.links)) {
        // Filter the links based on the search term
        const filteredLinks = item.links.filter(
          (link) =>
            link.generate_for?.toLowerCase().includes(searchTerm) ||
            link.meeting_name?.toLowerCase().includes(searchTerm) ||
            link.expirelink_datetime?.toLowerCase().includes(searchTerm) ||
            link.schedule_datetime?.toLowerCase().includes(searchTerm)
        );

        // Return the filtered item with updated links if any matches are found
        if (filteredLinks.length > 0) {
          return { ...item, links: filteredLinks };
        }
      }

      // If no matching links, return nothing
      return [];
    });

    console.log(filteredData, originalData);
    setNotifications(filteredData);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedNotification(null);
  };

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const joinMeeting = async (id) => {
    const sessionData = await JSON.parse(localStorage.getItem("student"));
    const language = localStorage.getItem("lang");
    const formData = new FormData();
    formData.append("student_id", sessionData?.id);
    formData.append("meeting_id", id);
    formData.append("lang", language);

    const requestOptions = {
      method: "POST",
      body: formData,
    };
    try {
      const response = await fetch(
        `${apiUrl}/getmeetingstatus.json`,
        requestOptions
      );
      const result = await response.json();
      setSelectedNotification(result?.message);
      setShowModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="You-Me Live" click={sidebarOpen} />
        <div className="card_board">
          <div className="content_card_title flex-view">
            Meeting List - ({notifications?.classname} (
            {notifications?.subjectname}))
            <button className="back-btn" onClick={goBack}>
              Back
            </button>
          </div>
          <div className="row clearfix" style={{ padding: "0px 20px" }}>
            <div className="col-lg-12">
              <div className="card">
                <div className="you-me-live-intro-container">
                  <h4 className="you-me-live-intro">Instructions*:</h4>
                  <ol className="you-me-live-intro-ol">
                    <li>For iPhones, Please use safari browsers.</li>
                    <li>
                      Before you start You-Me Live, please make sure pop up
                      blocker disabled on your browser.
                    </li>
                  </ol>
                </div>
                <div className="entries-and-search-container">
                  <div className="entries">
                    Show
                    <select
                      className="form-control"
                      value={limit}
                      onChange={handleLimitChange}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                    </select>
                    entries
                  </div>
                  <div className="entries">
                    Search:
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
                <div className="table-container">
                  <div className="table-responsive">
                    {loading ? (
                      <ThreeDots
                        visible={true}
                        height="80"
                        width="80"
                        color="#8e008e"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        wrapperClass=""
                      />
                    ) : (
                      // <p>Loading...</p>
                      <table
                        className="table table-hover js-basic-example dataTable table-custom table-striped m-b-0 c_list default_pageitem notification_table no-footer"
                        id="notification_table"
                        data-page-length="50"
                        role="grid"
                        aria-describedby="notification_table_info"
                      >
                        <thead className="thead-dark">
                          <tr role="row">
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Meeting Name: activate to sort column ascending"
                              style={{ width: "135.984px " }}
                            >
                              Meeting Name
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Link For: activate to sort column ascending"
                              style={{ width: "105.469px " }}
                            >
                              Link For
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Schedule Date/Time: activate to sort column ascending"
                              style={{ width: "184.078px" }}
                            >
                              Schedule Date/Time
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Expire Link: activate to sort column ascending"
                              style={{ width: "143.078px" }}
                            >
                              Expire Link
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Created Date: activate to sort column ascending"
                              style={{ width: "125.125px" }}
                            >
                              Created Date
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Action: activate to sort column ascending"
                              style={{ width: "124.266px" }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody id="notificationbody">
                          {notifications?.links?.map((data) => {
                            const createDate = data?.created_date
                              ? new Date(
                                  data?.created_date * 1000
                                ).toLocaleString("en-US", {
                                  month: "short",
                                  day: "numeric",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })
                              : new Date().toLocaleDateString();

                            const schedule_datetime = data?.schedule_datetime
                              ? new Date(
                                  data?.schedule_datetime * 1000
                                ).toLocaleString("en-US", {
                                  month: "short",
                                  day: "numeric",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })
                              : new Date().toLocaleDateString();

                            const expireDate = data?.expirelink_datetime
                              ? new Date(
                                  data?.expirelink_datetime * 1000
                                ).toLocaleString("en-US", {
                                  month: "short",
                                  day: "numeric",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })
                              : new Date().toLocaleDateString();
                            console.log(
                              data?.expirelink_datetime <
                                new Date().toLocaleDateString(),
                              "createDate"
                            );
                            return (
                              <tr
                                role="row"
                                className="odd"
                                key={data?.meeting_name}
                              >
                                <td>{data?.generate_for}</td>
                                <td>
                                  {data?.meeting_name.replace(/\+/g, " ")}
                                </td>
                                <td>{schedule_datetime}</td>
                                <td>{expireDate}</td>
                                <td>{createDate}</td>
                                <td>
                                  {data?.expirelink_datetime <=
                                    new Date().toLocaleDateString() && (
                                    <button
                                      onClick={() => joinMeeting(data?.id)}
                                      className="btn btn-sm btn-outline-secondary"
                                      style={{ cursor: "pointer" }}
                                    >
                                      Join Class
                                    </button>
                                  )}

                                  {data?.expirelink_datetime >=
                                    new Date().toLocaleDateString() && (
                                    <button
                                      // onClick={() => handleShowModal(data)}
                                      className="btn btn-sm btn-outline-secondary"
                                      style={{ cursor: "pointer" }}
                                    >
                                      Link Expired
                                    </button>
                                  )}

                                  {data?.status == 2 && (
                                    <button
                                      // onClick={() => handleShowModal(data)}
                                      className="btn btn-sm btn-outline-secondary"
                                      style={{ cursor: "pointer" }}
                                    >
                                      Meeting Ended
                                    </button>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
                <div className="pagination-container">
                  <p>
                    Show 1 to {limit} of {notifications?.length} entries
                  </p>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Custom Modal */}
      <div
        className={`student-Modal-container`}
        style={{ visibility: `${showModal ? "visible" : "hidden"}` }}
      >
        <div
          className={`modal-box ${showModal ? "transformOn" : "transformOff"}`}
          style={{ maxWidth: "400px", minWidth: "250px" }}
        >
          <h2>{selectedNotification}</h2>
          <div className="meeting-modal-btn-container">
            {" "}
            <button onClick={handleCloseModal}>OK</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YouMeLiveClassView;
