import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Pagination from "../components/Pagination";
import "../css/style.css";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";

const Announcement = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [originalData, setOriginalData] = useState([]); // Original fetched data

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const sessionData = await JSON.parse(localStorage.getItem("student"));
      const language = localStorage.getItem('lang');
      const offset = (currentPage - 1) * limit;
      const formData = new FormData();
      formData.append("studentid", sessionData?.id);
      formData.append("offset", offset);
      formData.append("limit", limit);
      formData.append('lang', language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      try {
        const response = await fetch(
          `${apiUrl}/notifications.json`,
          requestOptions
        );
        const result = await response.json();
        setNotifications(result.Announcements);
        setOriginalData(result?.Announcements);
        setTotalPages(Math.ceil(result.total_records / limit));
      } catch (error) {
        setError(error.message || "Something went wrong");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [apiUrl, currentPage, limit]);

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    setCurrentPage(1);
    const filteredData = originalData.filter(
      (item) =>
        (item.title?.toLowerCase() || "").includes(searchTerm.toLowerCase()) ||
        (item.added_by?.toLowerCase() || "").includes(searchTerm.toLowerCase())
    );

    setNotifications(filteredData);
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const handleShowModal = (notification) => {
    setSelectedNotification(notification);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedNotification(null);
  };

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="Announcement" click={sidebarOpen} />
        <div className="card_board">
          <div className="content_card_title flex-view">
            StudentProfile &gt; Announcements{" "}
            <button className="back-btn" onClick={goBack}>
              Back
            </button>
          </div>
          <div className="row clearfix" style={{ padding: "0px 20px" }}>
            <div className="col-lg-12">
              <div className="card">
                <div className="entries-and-search-container">
                  <div className="entries">
                    Show
                    <select
                      className="form-control"
                      value={limit}
                      onChange={handleLimitChange}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                    </select>
                    entries
                  </div>
                  <div className="entries">
                    Search:
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
                <div className="table-container">
                  <div className="table-responsive">
                    {loading ? (
                      <ThreeDots
                        visible={true}
                        height="80"
                        width="80"
                        color="#8e008e"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        wrapperClass=""
                      />
                    ) : (
                      // <p>Loading...</p>
                      <table
                        className="table table-hover js-basic-example dataTable table-custom table-striped m-b-0 c_list default_pageitem notification_table no-footer"
                        id="notification_table"
                        data-page-length="50"
                        role="grid"
                        aria-describedby="notification_table_info"
                      >
                        <thead className="thead-dark">
                          <tr role="row">
                            <th
                              className="sorting_asc"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-sort="ascending"
                              aria-label="activate to sort column descending"
                              style={{ width: "34px" }}
                            >
                              <label className="fancy-checkbox">
                                <input
                                  className="select-all"
                                  type="checkbox"
                                  name="checkbox"
                                />
                                <span></span>
                              </label>
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Topic: activate to sort column ascending"
                              style={{ width: "733.688px" }}
                            >
                              Topic
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="From: activate to sort column ascending"
                              style={{ width: "38.1406px" }}
                            >
                              From
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Date: activate to sort column ascending"
                              style={{ width: "61.5156px" }}
                            >
                              Date
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Action: activate to sort column ascending"
                              style={{ width: "40.4531px" }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody id="notificationbody">
                          {notifications.map((notification) => (
                            <tr
                              role="row"
                              className="odd"
                              key={notification?.id}
                            >
                              <td className="width45 sorting_1">
                                <label className="fancy-checkbox">
                                  <input
                                    className="checkbox-tick"
                                    type="checkbox"
                                    name="checkbox"
                                    value={notification?.id}
                                  />
                                </label>
                              </td>
                              <td>{notification?.title}</td>
                              <td>{notification?.added_by}</td>
                              <td>
                                {moment(
                                  notification?.schedule_date,
                                  "DD-MM-YYYY"
                                ).format("MMM DD, YYYY ")}
                              </td>
                              <td>
                                <button
                                  onClick={() => handleShowModal(notification)}
                                  className="btn btn-sm btn-outline-secondary"
                                  style={{ cursor: "pointer" }}
                                >
                                  <i className="bi bi-eye"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                  <div className="pagination-container">
                    <p>
                      Show 1 to {limit} of {notifications?.length} entries
                    </p>
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`student-Modal-container`}
        style={{ visibility: `${showModal ? "visible" : "hidden"}` }}
      >
        <div
          className={`modal-box modal-announcement ${
            showModal ? "transformOn" : "transformOff"
          }`}
        >
          <div className="modal-title">
            View Notification{" "}
            <i
              className="bi bi-x-lg"
              onClick={handleCloseModal}
              style={{ cursor: "pointer" }}
            ></i>
          </div>
          <div className="notification-title-container">
            <h5>
              Title: <span>{selectedNotification?.title}</span>
            </h5>
            <h5>
              Description:
              <span
                dangerouslySetInnerHTML={{
                  __html: selectedNotification?.description,
                }}
              />
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Announcement;
