import React, { useEffect, useState } from "react";
import "../css/style.css";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import { Link } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

const Scholarship = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [communityFilter, setCommunityFilter] = useState("newest");
  const [titleFilter, setTitleFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [error, setError] = useState("");
  const [apiSuccessMessage, setApiSuccessMessage] = useState("");
  const [scholarship, setScholarship] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    budget: "",
    academic_year: "",
    message: "",
  });

  const SERVERIMGURL = "https://you-me-globaleducation.org/school/img";

  const handleCommunityChange = (event) => {
    setCommunityFilter(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitleFilter(event.target.value);
  };

  useEffect(() => {
    const getScholarship = async () => {
      setLoading(true);
      const language = localStorage.getItem("lang");
      const formData = new FormData();
      formData.append("title", titleFilter);
      formData.append("filter", communityFilter);
      console.log(titleFilter, communityFilter);
      formData.append("lang", language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };
      try {
        const response = await fetch(
          `${apiUrl}/scholarship.json`,
          requestOptions
        );
        const result = await response.json();
        setScholarship(result);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getScholarship();
  }, [apiUrl, titleFilter, communityFilter]);

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // contact api
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const language = localStorage.getItem("lang");
    const formDatas = new FormData();
    formDatas.append("name", formData?.name);
    formDatas.append("email", formData?.email);
    formDatas.append("contact_no", formData?.contact);
    formDatas.append("scholar_title", formData?.academic_year);
    formDatas.append("scholar_description", formData?.message);
    formDatas.append("lang", language);

    const requestOptions = {
      method: "POST",
      body: formDatas,
    };
    try {
      const response = await fetch(
        `${apiUrl}/scholarshipContact.json`,
        requestOptions
      );
      const result = await response.json();
      setApiSuccessMessage(result?.message);
    } catch (error) {
      console.error(error);
      setError(error?.message);
    } finally {
      setLoading(false);
      setTimeout(() => {
        setError("");
        setApiSuccessMessage("");
      }, 3000);
      setTimeout(() => {
        handleCloseModal();
      }, 4000);
    }
  };

  // show contact modal
  const handleShowModal = () => {
    setShowModal(true);
  };

  // close contact modal
  const handleCloseModal = () => {
    setFormData({
      name: "",
      email: "",
      contact: "",
      budget: "",
      academic_year: "",
      message: "",
    });
    setShowModal(false);
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="You-Me Academy" click={sidebarOpen} />
        <div className="card_board">
          <div className="content_card_title">
            Scholarship
            <div className="selectboxes">
              <select
                className="form-control community_filter select2-hidden-accessible"
                id="comm_filter"
                onChange={handleCommunityChange}
                value={communityFilter}
              >
                <option value="newest">Newest</option>
                <option value="pdf">PDF</option>
                <option value="video">Video</option>
                <option value="word">Word Document</option>
                <option value="audio">Audio</option>
              </select>
              <button className="contact-us-btn" onClick={handleShowModal}>
                Contact Us
              </button>
              <span
                style={{ marginRight: "10px", marginBottom: "10px" }}
              ></span>
              <button className="back-btn" onClick={goBack}>
                Back
              </button>
            </div>
          </div>
          <div className="form-container">
            <div className="drobbox-body">
              <select
                className="form-control js-states chosetitle select2-hidden-accessible"
                id="title_filter"
                onChange={handleTitleChange}
                value={titleFilter}
              >
                <option value="">Choose Title</option>
                {scholarship?.title_details?.map((t) => (
                  <option key={t} value={t?.title}>
                    {t?.title}
                  </option>
                ))}
              </select>

              {loading ? (
                <ThreeDots
                  visible={true}
                  height="80"
                  width="80"
                  color="#8e008e"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  wrapperClass=""
                />
              ) : (
                // <p>Loading...</p>
                <div className="data-body">
                  <div className="row col-sm-12" id="viewcommunity">
                    {scholarship?.know_details?.map((sch) => {
                      return (
                        <div
                          className="col-sm-2 col_img"
                          key={sch.id}
                          style={{ marginBottom: "20px" }}
                        >
                          <Link
                            to={`/dashboard/student-knowledge/scholarship/view/${sch.id}`}
                            className="viewknow"
                          >
                            <img
                              src={`${SERVERIMGURL}/${sch.image}`}
                              alt="scholarship Icon"
                            />
                            <div className="set_icon">
                              <i
                                className={`${
                                  sch?.file_type === "pdf"
                                    ? "bi bi-file-pdf"
                                    : sch?.file_type === "video"
                                    ? " bi bi-camera-video"
                                    : sch?.file_type === "audio"
                                    ? "bi bi-headphones"
                                    : "bi bi-filetype-doc"
                                }`}
                              ></i>
                            </div>
                          </Link>
                          <p className="title" style={{ color: "#000" }}>
                            <b>Title</b>: {sch.title}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {/* {!scholarship?.length ? <h3 style={{ color: "gray", margin: "0" }}>Data Not Found</h3> : ""} */}
              <form
                method="post"
                acceptCharset="utf-8"
                action="/school/Dropbox/"
                style={{ display: "none" }}
              >
                <div>
                  <input type="hidden" name="_method" value="POST" />
                  <input
                    type="hidden"
                    name="_csrfToken"
                    autoComplete="off"
                    value="fd82b978dfb069d344e73b0d4e9dca020ee9d23fbca43988689186f03e216fc58422d41d5d040c943c9761c1bcb0be38cb915d43025ce94bebffabf8685a6482"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Custom Modal */}
      <div
        className={`student-Modal-container`}
        style={{ visibility: `${showModal ? "visible" : "hidden"}` }}
      >
        <div
          className={`modal-box ${showModal ? "transformOn" : "transformOff"}`}
          style={{ maxWidth: "50%", minWidth: "300px" }}
        >
          <div className="custom-modal-title">
            <span style={{ display: "flex", alignItems: "center" }}>
              {" "}
              Scholarship Contact Us
            </span>{" "}
            <i
              className="bi bi-x-lg"
              onClick={handleCloseModal}
              style={{ cursor: "pointer" }}
            ></i>
          </div>
          <div className="form-container">
            <form
              onSubmit={(e) => handleSubmit(e)}
              encType="multipart/form-data"
              className="contact-modal"
            >
              {/* first row */}
              <div className="form-row" style={{ marginBottom: "10px" }}>
                <div
                  className="form-group form-control"
                  style={{ width: "45%" }}
                >
                  <label>Name*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={formData.name}
                    placeholder="Enter Name"
                    required
                    onChange={handleChange}
                  />
                </div>
                <div
                  className="form-group form-control"
                  style={{ width: "45%" }}
                >
                  <label>Email*</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={formData.email}
                    placeholder="Enter Email"
                    required
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* second row */}
              <div className="form-row" style={{ marginBottom: "10px" }}>
                <div
                  className="form-group form-control"
                  style={{ width: "45%" }}
                >
                  <label>Contact No.*</label>
                  <input
                    type="number"
                    className="form-control"
                    name="contact"
                    value={formData.contact}
                    placeholder="Enter Contact No."
                    required
                    onChange={handleChange}
                  />
                </div>
                <div
                  className="form-group form-control"
                  style={{ width: "45%" }}
                >
                  <label>Scholarship Title*</label>
                  <select
                    className="form-control"
                    required
                    style={{ marginBottom: "0" }}
                    name="academic_year"
                    value={formData.academic_year}
                    onChange={handleChange}
                  >
                    <option value="">Choose Title</option>
                    {scholarship?.know_details?.map((val, i) => {
                      return (
                        <option value={val?.title} key={i}>
                          {val?.title}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              {/* third row */}
              <div className="form-row" style={{ marginBottom: "10px" }}>
                <div
                  className="form-group form-control"
                  style={{ width: "100%" }}
                >
                  <label>Scholarship Description*</label>
                  <textarea
                    placeholder="Enter Description"
                    name="message"
                    required
                    value={formData.message}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {apiSuccessMessage !== "" && (
                <p
                  style={{
                    color: "white",
                    backgroundColor: "green",
                    padding: "10px",
                  }}
                >
                  {apiSuccessMessage}
                </p>
              )}
              {error !== "" && (
                <p
                  style={{
                    color: "white",
                    backgroundColor: "red",
                    padding: "10px",
                  }}
                >
                  {error}
                </p>
              )}

              {/* btn-row */}
              <div className="btn-container">
                <button
                  type="submit"
                  id="editstdntprfbtn"
                  className="btn btn-primary form-update"
                >
                  Send
                </button>
                <span style={{ margin: "0px 10px" }}></span>
                <button
                  id="editstdntprfbtn"
                  className="btn btn-primary form-update"
                  onClick={handleCloseModal}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Scholarship;
