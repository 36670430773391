import React, { useEffect, useState } from "react";
import "../css/style.css";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import { Link } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import dummyImage from "../images/logo.png";
import { useMessages } from "../context/MessageContext";

const MySchoolKnowledgeCenter = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [communityFilter, setCommunityFilter] = useState("newest");
  const [dropbox, setDropbox] = useState([]);
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const SERVERIMGURL = "https://you-me-globaleducation.org/school/img";
  const { showSuccess, showError, clearMessages } = useMessages();
  const [showModal, setShowModal] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    budget: "",
    academic_year: "",
    message: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const sessionData = await JSON.parse(localStorage.getItem("student"));
      const language = localStorage.getItem('lang');
      const formData = new FormData();
      formData.append("school_id", sessionData?.school_id);
      formData.append("filter", communityFilter);
      formData.append('lang', language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };
      try {
        // all contries api
        const response = await fetch(
          `${apiUrl}/schoolknowledge.json`,
          requestOptions
        );
        const result = await response.json();
        setDropbox(result);
      } catch (error) {
        console.log("file error", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [apiUrl, communityFilter]);

  const handleCommunityChange = (event) => {
    setCommunityFilter(event.target.value);
  };

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // contact api
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const language = localStorage.getItem('lang');
    const formDatas = new FormData();
    formDatas.append("name", formData?.name);
    formDatas.append("email", formData?.email);
    formDatas.append("contact_no", formData?.contact);
    formDatas.append("leadership_title", formData?.academic_year);
    formDatas.append("leadership_description", formData?.message);
    formData.append('lang', language);

    const requestOptions = {
      method: "POST",
      body: formDatas,
    };
    try {
      const response = await fetch(
        `${apiUrl}/leadershipContact.json`,
        requestOptions
      );
      const result = await response.json();
      showSuccess(result?.message);
    } catch (error) {
      console.error(error);
      showError(error?.message);
    } finally {
      setLoading(false);
      setTimeout(() => {
        clearMessages();
      }, 2000);
      setTimeout(() => {
        handleCloseModal();
      }, 3000);
    }
  };

  // show contact modal
  const handleShowModal = () => {
    setShowModal(true);
  };

  // close contact modal
  const handleCloseModal = () => {
    setFormData({
      name: "",
      email: "",
      contact: "",
      budget: "",
      academic_year: "",
      message: "",
    });
    setShowModal(false);
  };
  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="My School" click={sidebarOpen} />
        <div className="card_board">
          <div className="content_card_title">
            Knowledge Center
            <div className="selectboxes">
              <select
                className="form-control community_filter select2-hidden-accessible"
                id="comm_filter"
                onChange={handleCommunityChange}
                value={communityFilter}
              >
                <option value="newest">Newest</option>
                <option value="pdf">PDF</option>
                <option value="video">Video</option>
                <option value="word">Word Document</option>
                <option value="audio">Audio</option>
              </select>
              <span
                style={{ marginRight: "10px", marginBottom: "10px" }}
              ></span>
              <button className="back-btn" onClick={goBack}>
                Back
              </button>
            </div>
          </div>
          <div className="form-container">
            <div className="drobbox-body">
              {loading ? (
                <ThreeDots
                  visible={true}
                  height="80"
                  width="80"
                  color="#8e008e"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  wrapperClass=""
                />
              ) : (
                // <p>Loading...</p>
                <div className="data-body">
                  <div className="data-body-row" id="viewcommunity">
                    {dropbox?.retrieve_content?.map((work) => {
                      console.log(work.id, "idddddddddddd");
                      return (
                        <div
                          className="col-sm-2 col_img"
                          key={work.id}
                          style={{ marginBottom: "20px" }}
                        >
                          <Link
                            to={`/dashboard/my_school/knowledge_center/view/${work.id}`}
                            className="viewknow"
                          >
                            {work?.image !== "" ? (
                              <img
                                src={`${SERVERIMGURL}/${work?.image}`}
                                alt="Dropbox Icon"
                              />
                            ) : (
                              <img src={dummyImage} alt="Dropbox Icon" />
                            )}
                            <div className="set_icon">
                              <i
                                className={`${
                                  work?.file_type === "pdf"
                                    ? "bi bi-file-pdf"
                                    : work?.file_type === "video"
                                    ? " bi bi-camera-video"
                                    : work?.file_type === "audio"
                                    ? "bi bi-headphones"
                                    : "bi bi-filetype-doc"
                                }`}
                              ></i>
                            </div>
                          </Link>
                          <p className="title" style={{ color: "#000" }}>
                            <b>Titre</b>: {work?.file_title}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {/* {!dropbox?.length ? <h3 style={{ color: "gray", margin: "0" }}>Data Not Found</h3> : ""} */}
              <form
                method="post"
                acceptCharset="utf-8"
                action="/school/Dropbox/"
                style={{ display: "none" }}
              >
                <div>
                  <input type="hidden" name="_method" value="POST" />
                  <input
                    type="hidden"
                    name="_csrfToken"
                    autoComplete="off"
                    value="fd82b978dfb069d344e73b0d4e9dca020ee9d23fbca43988689186f03e216fc58422d41d5d040c943c9761c1bcb0be38cb915d43025ce94bebffabf8685a6482"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MySchoolKnowledgeCenter;
