import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import ContentTitle from "../components/ContentTitle";

const ReportCard = () => {
  const { apiUrlLink } = useParams();
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const studentData = JSON.parse(localStorage.getItem("student"));
  const [classData, setClassData] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [retrive, setRetrive] = useState([]);
  const [extra, setExtra] = useState([]);
  const [htmlContent, setHtmlContent] = useState("");
  const [studentName, setstudentName] = useState({
    name: "",
    gender: "",
  });
  const [cnicNo, setCnicNo] = useState([
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
  ]);
  const [form1Code, setForm1Code] = useState([
    { value: "1" },
    { value: "1" },
    { value: "1" },
    { value: "1" },
    { value: "1" },
    { value: "1" },
    { value: "1" },
    { value: "1" },
    { value: "1" },
    { value: "1" },
    { value: "1" },
    { value: "1" },
  ]);
  const [matriCode, setMatriCode] = useState([
    { value: "1" },
    { value: "1" },
    { value: "1" },
    { value: "1" },
    { value: "1" },
    { value: "1" },
    { value: "1" },
    { value: "1" },
    { value: "1" },
    { value: "1" },
    { value: "1" },
    { value: "1" },
  ]);
  const [sidebarToggle, setSidebarToggle] = useState(true);

  // useEffect(() => {
  //   const getClasses = async () => {
  //     const language = localStorage.getItem("lang");
  //     const requestOptions = {
  //       method: "GET",
  //     };
  //     try {
  //       const response = await fetch(
  //         `${apiUrl}/${apiUrlLink}?classid=${studentData?.class}&studentid=${studentData?.id}&lang=${language}`,
  //         requestOptions
  //       );
  //       const result = await response.json();
  //       console.log("r", result);
  //       // setClassData(result?.classes_name?.c_name);
  //       // setstudentName({
  //       //   name:
  //       //     result?.student_name?.f_name + " " + result?.student_name?.l_name,
  //       //   gender: result?.student_name?.gender,
  //       // });
  //       // setRetrive(result?.retrieve_record);
  //       // setSubjects(result?.subject_names);
  //       // // cnic
  //       // result?.report_marks?.nid?.split(",")?.map((e, i) => {
  //       //   let cinicArray = [...cnicNo];
  //       //   cinicArray[i].value = e;
  //       //   return setCnicNo(cinicArray);
  //       // });
  //       // // code
  //       // result?.report_marks?.code?.split(",")?.map((e, i) => {
  //       //   let cinicArray = [...form1Code];
  //       //   cinicArray[i].value = e;
  //       //   return setForm1Code(cinicArray);
  //       // });
  //       // // nperm
  //       // result?.report_marks?.nperm?.split(",")?.map((e, i) => {
  //       //   let cinicArray = [...matriCode];
  //       //   cinicArray[i].value = e;
  //       //   return setMatriCode(cinicArray);
  //       // });
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   getClasses();
  // }, [apiUrl, apiUrlLink, studentData?.class, studentData?.id]);

  useEffect(() => {
    const getClasses = async () => {
      const language = localStorage.getItem("lang");
      const requestOptions = {
        method: "GET",
      };
      try {
        const response = await fetch(
          `${apiUrl}/${apiUrlLink}?classid=${studentData?.class}&studentid=${studentData?.id}&lang=${language}`,
          requestOptions
        );
        console.log(response?.url, "response");
        setClassData(response?.url);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getClasses();
  }, [apiUrl, apiUrlLink, studentData?.class, studentData?.id]);
  console.log("HTML Content:", htmlContent);
  useEffect(() => {
    // extra
    const newFun = async () => {
      const newArray = subjects?.map((e) => {
        const filterSub = retrive?.filter((v) => {
          return v?.subject_id === e.id;
        });
        const child = filterSub?.map((m) => {
          return {
            childName: m?.period_name,
            semesterName: m?.semester_name,
            marks: m.max_marks,
          };
        });
        return {
          subjectID: filterSub[0]?.subject_id,
          subjectName: filterSub[0]?.subject_name || "",
          child: child,
        };
      });
      setExtra(newArray);
    };

    newFun();
  }, [retrive, subjects]);

  console.log(extra, "extra");

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const handleCnicChange = (index, e) => {
    let cinicArray = [...cnicNo];
    cinicArray[index].value = e.target.value;
    setCnicNo(cinicArray);
  };

  const handleCode1Change = (index, e) => {
    let cinicArray = [...form1Code];
    cinicArray[index].value = e.target.value;
    setForm1Code(cinicArray);
  };

  const handleCode2Change = (index, e) => {
    let cinicArray = [...matriCode];
    cinicArray[index].value = e.target.value;
    setMatriCode(cinicArray);
  };

  const handleMarkChange = (value, id, index) => {
    let cinicArray = [...extra];
    cinicArray[index].child[id].marks = value;
    setExtra(cinicArray);
  };

  console.log(apiUrlLink, "apiUrlLink");

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <div className={`main containerFull`}>
        <ContentTitle name="Student Dashboard" click={sidebarOpen} />
        <div
          className=""
          style={{
            overflow: "auto",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <div className="content_card_title flex-view">
            Report Card
            <button className="back-btn" onClick={goBack}>
              Back
            </button>
          </div>
          <div className="reportCard-iframe-container">
            <iframe src={classData} title="iframe link"></iframe>
          </div>

          <div
            dangerouslySetInnerHTML={{ __html: htmlContent }} // Render HTML content safely
          />
          {/* <div
            className="report-card-container"
            style={{ fontFamily: "serif" }}
          >
            {/* header */}
          {/* <div className="report-card-header">
              <div className="report-card-header-img-container">
                <img
                  src="https://you-me-globaleducation.org/school/img/logoSecond.png"
                  alt="report-card-image"
                />
              </div>
              <h2>
                RÉPUBLIQUE DEMOCRATIQUE DU CONGO MINISTERE DE L’ENSEIGNEMENT
                PRIMAIRE, SECONDAIRE ET PROFESSIONNEL
              </h2>
            </div> */}
          {/* header end */}
          {/* cnic */}
          {/* <div className="report-card-cnic-container">
            <label>N° ID.</label>
            {cnicNo?.map((val, index) => {
              return (
                <div className="cnic-input-container" key={index}>
                  <input
                    type="text"
                    value={val?.value}
                    onChange={(e) => handleCnicChange(index, e)}
                  />
                </div>
              );
            })}
          </div> */}
          {/* cnic end */}
          {/* form */}
          {/* <div className="report-cart-forms-wrapper"> */}
          {/* left */}
          {/* <div className="form-left-container">
              <div className="input-container">
                <label>PROVINCE :</label>
                <div className="input-container-input">
                  <input type="text" />
                </div>
              </div>
              <div className="input-container">
                <label>VILLE :</label>
                <div className="input-container-input">
                  <input type="text" />
                </div>
              </div>
              <div className="input-container">
                <label>COMMUNE/TER. (1) :</label>
                <div className="input-container-input">
                  <input type="text" />
                </div>
              </div>
              <div className="input-container">
                <label>ECOLE :</label>
                <div className="input-container-input">
                  <input type="text" />
                </div>
              </div>
              <div className="input-container" style={{ marginTop: "20px" }}>
                <label>CODE :</label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {form1Code?.map((val, index) => {
                    return (
                      <div className="cnic-input-container" key={index}>
                        <input
                          type="text"
                          value={val?.value}
                          onChange={(e) => handleCode1Change(index, e)}
                          maxLength={1}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div> */}
          {/* right */}
          {/* <div className="form-left-container">
              <div className="input-container">
                <div className="input-container">
                  <label>ELEVE:</label>
                  <div className="input-container-input">
                    <input
                      type="text"
                      value={studentName.name}
                      onChange={(e) =>
                        setstudentName((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div
                  className="input-container"
                  style={{ width: "200px", justifyContent: "space-around" }}
                >
                  <label>SEXE:</label>
                  <div
                    className="input-container-input"
                    style={{ width: "70px", minWidth: "unset" }}
                  >
                    <input
                      type="text"
                      value={studentName.gender}
                      onChange={(e) =>
                        setstudentName((prev) => ({
                          ...prev,
                          gender: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="input-container">
                <div className="input-container">
                  <label>NE(E) A :</label>
                  <div className="input-container-input">
                    <input type="text" />
                  </div>
                </div>
                <div
                  className="input-container"
                  style={{ width: "200px", justifyContent: "space-around" }}
                >
                  <label>,LE</label>
                  <div
                    className="input-container-input"
                    style={{ width: "70px", minWidth: "unset" }}
                  >
                    <input type="text" />
                  </div>
                </div>
              </div>
              <div className="input-container">
                <label>CLASSE :</label>
                <div className="input-container-input">
                  <input
                    type="text"
                    value={classData}
                    onChange={(e) => setClassData(e.target.value)}
                  />
                </div>
              </div>
              <div className="input-container" style={{ marginTop: "70px" }}>
                <label>MATRICULE</label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {matriCode?.map((val, index) => {
                    return (
                      <div className="cnic-input-container" key={index}>
                        <input
                          type="text"
                          value={val?.value}
                          onChange={(e) => handleCode2Change(index, e)}
                          maxLength={1}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div> */}
          {/* form end */}
          {/* branches */}
          {/* <div className="branches-wrapper">
            <h3>
              BULLETIN DE L 'ELEVE DEGRE MOYEN (3ème, 4ème ANNEE) (1) ANNEE
              SCOLAIRE 20…..20…..
            </h3>
            <table>
              <tbody>
                <tr>
                  <td rowSpan="2">BRANCHES</td>
                  <td colSpan="7" className="bb">
                    PREMIER TRIMESTRE
                  </td>
                  <td colSpan="7" className="bb">
                    DEUXIEME TRIMESTRE
                  </td>
                  <td colSpan="7" className="bb">
                    TROISIEME TRIMESTRE
                  </td>
                  <td colSpan="2" className="bb">
                    TOTAL
                  </td>
                </tr>
                <tr>
                  <td>MAX per</td>
                  <td>1eP</td>
                  <td>MAX per</td>
                  <td>1eP</td>
                  <td>MAX per</td>
                  <td>1eP</td>
                  <td>MAX per</td>
                  <td>1eP</td>
                  <td>MAX per</td>
                  <td>1eP</td>
                  <td>MAX per</td>
                  <td>1eP</td>
                  <td>MAX per</td>
                  <td>1eP</td>
                  <td>MAX per</td>
                  <td>1eP</td>
                  <td>MAX per</td>
                  <td>1eP</td>
                  <td>MAX per</td>
                  <td>1eP</td>
                  <td>MAX per</td>
                  <td>1eP</td>
                  <td>MAX per</td>
                </tr>
              </tbody>
            </table>
          </div> */}
          {/* branches end */}
          {/* languages */}
          {/* <div className="languages-wrapper">
              {extra?.map((e, index) => {
                return (
                  <>
                    {e?.child?.length > 0 &&
                      e?.child?.map((m, i) => {
                        return (
                          <>
                            <h4
                              style={{
                                textAlign: "center",
                                margin: "10px 0px",
                              }}
                            >
                              {m?.semesterName}
                            </h4>
                            <div className="languages-wrapper">
                              <h4>{e?.subjectName}</h4>
                              <table>
                                <tbody>
                                  <tr>
                                    <td>{m?.childName}</td>
                                    <td>10</td>
                                    <td>
                                      <input
                                        type="text"
                                        value={m?.marks}
                                        onChange={(e) =>
                                          handleMarkChange(
                                            e.target.value,
                                            i,
                                            index
                                          )
                                        }
                                        style={{ color: "blue" }}
                                      />
                                    </td>
                                    <td>
                                      <input type="text" />
                                    </td>
                                    <td>20</td>
                                    <td>
                                      <input type="text" />
                                    </td>
                                    <td>40</td>
                                    <td>
                                      <input type="text" value={m?.marks} />
                                    </td>
                                    <td>10</td>
                                    <td>
                                      <input type="text" />
                                    </td>
                                    <td>
                                      <input type="text" />
                                    </td>
                                    <td>20</td>
                                    <td>
                                      <input type="text" />
                                    </td>
                                    <td>40</td>
                                    <td>
                                      <input type="text" value={0} />
                                    </td>
                                    <td>10</td>
                                    <td>
                                      <input type="text" />
                                    </td>
                                    <td>
                                      <input type="text" />
                                    </td>
                                    <td>20</td>
                                    <td>
                                      <input type="text" />
                                    </td>
                                    <td>40</td>
                                    <td>
                                      <input type="text" value={0} />
                                    </td>
                                    <td>120</td>
                                    <td>
                                      <input type="text" value={m?.marks} />
                                    </td>
                                  </tr> */}
          {/* sub total */}
          {/* <tr>
                                    <td>
                                      <b>Sous-Total</b>
                                    </td>
                                    <td>10</td>
                                    <td>
                                      <input type="text" value={m?.marks} />
                                    </td>
                                    <td>
                                      <input type="text" value={0} />
                                    </td>
                                    <td>40</td>
                                    <td>
                                      <input type="text" value={0} />
                                    </td>
                                    <td>100</td>
                                    <td>
                                      <input type="text" value={0} />
                                    </td>
                                    <td>25</td>
                                    <td>
                                      <input type="text" value={0} />
                                    </td>
                                    <td>
                                      <input type="text" value={0} />
                                    </td>
                                    <td>50</td>
                                    <td>
                                      <input type="text" value={0} />
                                    </td>
                                    <td>100</td>
                                    <td>
                                      <input type="text" value={0} />
                                    </td>
                                    <td>25</td>
                                    <td>
                                      <input
                                        type="text"
                                        value={0}
                                        style={{ color: "red" }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={0}
                                        style={{ color: "red" }}
                                      />
                                    </td>
                                    <td>50</td>
                                    <td>
                                      <input
                                        type="text"
                                        value={0}
                                        style={{ color: "red" }}
                                      />
                                    </td>
                                    <td>100</td>
                                    <td>
                                      <input type="text" value={0} />
                                    </td>
                                    <td>300</td>
                                    <td>
                                      <input type="text" value={0} />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </>
                        );
                      })}
                  </>
                );
              })}
            </div> */}
          {/* language end */}
          {/* report footer */}
          {/* <div className="report-footer-wrapper"> */}
          {/* top inputs */}
          {/* <div className="report-footer-input-wrapper">
                <div
                  className="input-container"
                  style={{
                    width: "600px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="input-container"
                    style={{
                      width: "400px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    <label>FAIT A</label>
                    <div
                      className="input-container-input"
                      style={{ height: "50px" }}
                    >
                      <input type="text" />
                    </div>
                  </div>
                  <div
                    className="input-container"
                    style={{
                      width: "200px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    <label>,LE</label>
                    <div
                      className="input-container-input"
                      style={{
                        width: "70px",
                        minWidth: "unset",
                        height: "50px",
                      }}
                    >
                      <input type="text" />
                    </div>
                  </div>
                </div>
              </div> */}
          {/* top inputs end */}
          {/* <p>- L’élève passe dans la classe supérieure (1)</p>
              <p>- L’élève double sa classe (1)</p>
              <p style={{ textAlign: "end" }}>LE CHEF D'ÉTABLISSEMENT</p>
              <p style={{ textAlign: "end" }}>NOM & SIGNATURE</p>
              <p style={{ textAlign: "center" }}>NOM & SIGNATURE</p>
              <p>(1) Biffer la mention inutile</p>
              <div className="footer-bottom">
                <p>
                  Note importante : Le bulletin est sans valeur s’il est raturé
                  ou surchargé.
                </p>
                <p style={{ textAlign: "end" }}>IGE/P.S/005</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ReportCard;
