import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import { ThreeDots } from "react-loader-spinner";
import CryptoJS from "crypto-js";
import PieChart from "../components/PieChart";

const MyFees = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [classes, setClasses] = useState(""); // State for classes
  const [feesData, setFeesData] = useState([]); // State for classes
  const studentData = JSON.parse(localStorage.getItem("student"));
  const [loading, setLoading] = useState(false);

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  //   fees data api
  useEffect(() => {
    const getClasses = async () => {
      setLoading(true);
      const language = localStorage.getItem("lang");
      //  Convert number to string
      const numberString = studentData?.id.toString();
      // Create MD5 hash
      const md5Hash = CryptoJS.MD5(numberString).toString();

      const formData = new FormData();
      formData.append("stid", md5Hash);
      formData.append("lang", language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };
      try {
        const response = await fetch(
          `${apiUrl}/studentfee.json`,
          requestOptions
        );
        const result = await response.json();
        setFeesData(result?.student_fee);
        setClasses(result?.cls_sess);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getClasses();
  }, [apiUrl, studentData?.id]);

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="Student Fee" click={sidebarOpen} />
        <div className="card_board">
          <div className="content_card_title flex-view">
            Fees Structure
            <div
              style={{
                textAlign: "center",
                color: "#8e008e",
              }}
            >
              <b>Class: {classes}</b>
            </div>
            <button className="back-btn" onClick={goBack}>
              Back
            </button>
          </div>
          <div className="fees-container">
            <div className="form-div">
              <div className="table-container">
                <div className="table-responsive">
                  {loading ? (
                    <ThreeDots
                      visible={true}
                      height="80"
                      width="80"
                      color="#8e008e"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      wrapperClass=""
                    />
                  ) : (
                    // <p>Loading...</p>
                    <table
                      className="table table-hover js-basic-example dataTable table-custom table-striped m-b-0 c_list default_pageitem notification_table no-footer"
                      id="notification_table"
                      data-page-length="50"
                      role="grid"
                      aria-describedby="notification_table_info"
                    >
                      <thead className="thead-dark">
                        <tr role="row">
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="notification_table"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Course Name: activate to sort column ascending"
                          >
                            Installment
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="notification_table"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Type: activate to sort column ascending"
                          >
                            Amount(In US Dollar)
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="notification_table"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Periode: activate to sort column ascending"
                          >
                            Paid
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="notification_table"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Marks: activate to sort column ascending"
                          >
                            Due
                          </th>
                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="notification_table"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Grades: activate to sort column ascending"
                          >
                            View Receipt
                          </th>
                        </tr>
                      </thead>
                      <tbody id="notificationbody">
                        {feesData?.map((fee, index) => {
                          return (
                            <>
                              <tr role="row" className="odd" key={index}>
                                <td>{fee?.installment}</td>
                                <td>{fee?.amount}</td>
                                <td>{fee?.paid}</td>
                                <td>{fee?.pending}</td>
                                <td>Pending</td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
            <div className="chart-div-container">
              <h2>Fees Analysis</h2>
              <div className="chart-div">
                <PieChart due={feesData[0]?.amount} paid={feesData[0]?.paid} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyFees;
