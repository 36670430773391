// src/admin/Dashboard.js
import React, { useState, useEffect } from "react";
import "../css/style.css"; // Import your CSS file
import { Link, useLocation } from "react-router-dom";

const Sidebar = ({ addClass }) => {
  const path = useLocation();
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [notifications, setNotifications] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const sessionData = await JSON.parse(localStorage.getItem("student"));
      const language = localStorage.getItem("lang");

      const formData = new FormData();
      formData.append("studentid", sessionData?.id);
      formData.append("offset", 0);
      formData.append("lang", language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      try {
        const response = await fetch(
          `${apiUrl}/notifications.json`,
          requestOptions
        );
        const result = await response.json();
        setNotifications(result.total_records);
        console.log(result);
      } catch (error) {}
    };

    fetchData();
  }, [apiUrl]);

  // State to handle the active section
  const [activeSection, setActiveSection] = useState("class-subjects");

  // Handler to switch sections
  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  // sidebar menu item array
  const menuItems = [
    {
      id: 0,
      pathLink: "/dashboard",
      iconClass: "bi bi-speedometer",
      itemName: "Dashboard",
    },
    {
      id: 1,
      pathLink: "/dashboard/student-knowledge",
      iconClass: "bi bi-book-half",
      itemName: "You-Me Academy",
    },
    {
      id: 2,
      pathLink: "https://learn.eltngl.com/",
      iconClass: "bi bi-globe-americas",
      itemName: "National Geographic",
      target: "_blank",
    },
    {
      id: 3,
      pathLink: "/dashboard/announcement",
      iconClass: "bi bi-megaphone",
      itemName: "Announcement",
      number: `(${notifications})`,
    },
    {
      id: 4,
      pathLink: "/dashboard/attendance",
      iconClass: "bi bi-person-vcard",
      itemName: "Attendance",
    },
    {
      id: 5,
      pathLink: "/dashboard/calender",
      iconClass: "bi bi-calendar-check",
      itemName: "Calendar",
    },
    {
      id: 6,
      pathLink: "/dashboard/canteen",
      iconClass: "bi bi-cup-hot-fill",
      itemName: "Canteen",
    },
    {
      id: 7,
      pathLink: "/dashboard/school_contacts",
      iconClass: "bi bi-chat-left-text",
      itemName: "Contact School",
      number: `(${0})`,
    },
    {
      id: 8,
      pathLink: "/dashboard/events",
      iconClass: "bi bi-image",
      itemName: "Events",
    },
    {
      id: 9,
      pathLink: "/dashboard/library",
      iconClass: "bi bi-collection",
      itemName: "Library",
    },
    {
      id: 10,
      pathLink: "/dashboard/my-fees",
      iconClass: "bi bi-currency-dollar",
      itemName: "My Fees",
    },
    {
      id: 11,
      pathLink: "/dashboard/student_diary",
      iconClass: "bi bi-file-earmark",
      itemName: "Student Diary",
    },
    {
      id: 12,
      pathLink: "/dashboard/you-me-live",
      iconClass: "bi bi-link-45deg",
      itemName: "You-Me Live",
    },
    {
      id: 13,
      pathLink: "/dashboard/schedule",
      iconClass: "bi bi-calendar-date",
      itemName: "Schedule",
    },
    {
      id: 14,
      pathLink: "/dashboard/tutoring_center",
      iconClass: "bi bi-backpack2",
      itemName: "Tutoring Center",
    },
    {
      id: 15,
      pathLink: "/dashboard/my_school",
      iconClass: "bi bi-book",
      itemName: "My School",
    },
    {
      id: 16,
      pathLink: "/dashboard/marketplace",
      iconClass: "bi bi-suitcase-lg-fill",
      itemName: "You-Me Market Place",
    },
  ];

  return (
    <ul className={`menu ${addClass}`}>
      {menuItems.map((v) => {
        if (v.target === "_blank") {
          return (
            <a
              href={v.pathLink}
              className={`link-btn ${
                path.pathname === v.pathLink ? "navActive" : "navInactive"
              }`}
              key={v.id}
              target={v.target}
              rel="noopener noreferrer" // Adds security to prevent tab-nabbing attacks
            >
              <li className="menu-item">
                <i className={v.iconClass}></i> {v.itemName} {v.number}
              </li>
            </a>
          );
        }
        return v.itemName !== "Announcement" ? (
          <Link
            to={v.pathLink}
            className={`${
              path.pathname === v.pathLink ? "navActive" : "navInactive"
            } link-btn `}
            key={v.id}
          >
            <li className="menu-item">
              <i className={v.iconClass}></i> {v.itemName} {v.number}
            </li>
          </Link>
        ) : (
          <Link
            to={v.pathLink}
            className={`${
              path.pathname === v.pathLink ? "navActive" : "navInactive"
            } link-btn announcementLinkBtn`}
            key={v.id}
            style={{ background: "#f3e83c"}}
          >
            <li className="menu-item">
              <i className={v.iconClass}></i> {v.itemName} {v.number}
            </li>
          </Link>
        );
      })}
    </ul>
  );
};

export default Sidebar;
