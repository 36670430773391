import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import { Pagination } from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";
import CryptoJS from "crypto-js";

const Schedule = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [classes, setClasses] = useState([]); // State for classes
  const studentData = JSON.parse(localStorage.getItem("student"));
  const [loading, setLoading] = useState(false);
  const [schedule, setSchedule] = useState([]);

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  useEffect(() => {
    const getClasses = async () => {
      const language = localStorage.getItem("lang");

      const requestOptions = {
        method: "GET",
      };
      try {
        const response = await fetch(
          `${apiUrl}/classlist.json?school_id=${studentData?.school_id}&lang=${language}`,
          requestOptions
        );
        const result = await response.json();
        console.log(result, "result");
        const filterClass = await result?.classes?.filter((v) => {
          return v?.id == studentData?.class;
        });
        setClasses(filterClass); // Assuming your API returns an array of classes
      } catch (error) {
        console.error(error);
      }
    };

    getClasses();
  }, [apiUrl]);

  // schedule api
  useEffect(() => {
    const getClasses = async () => {
      setLoading(true);
      const language = localStorage.getItem("lang");
      const sessionData = await JSON.parse(localStorage.getItem("student"));
      //  Convert number to string
      const numberString = sessionData?.id?.toString();
      // Create MD5 hash
      const md5Hash = CryptoJS.MD5(numberString).toString();

      const formData = new FormData();
      formData.append("studentid", md5Hash);
      formData.append("sessionid", sessionData?.session_id);
      formData.append("lang", language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };
      try {
        const response = await fetch(
          `${apiUrl}/studentschedule.json`,
          requestOptions
        );
        const result = await response.json();
        setSchedule(result);
        console.log(result, "result");
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getClasses();
  }, [apiUrl]);

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="Schedule" click={sidebarOpen} />
        <div className="card_board">
          <div className="content_card_title flex-view">
            Schedule
            {classes.length === 1 && (
              <div
                style={{
                  textAlign: "center",
                  color: "#8e008e",
                }}
              >
                <b>
                  Class: {classes[0]?.c_name}-{classes[0]?.c_section}(
                  {classes[0]?.school_sections}){" "}
                </b>
              </div>
            )}
            <button className="back-btn" onClick={goBack}>
              Back
            </button>
          </div>
          <div className="schedule-table-container">
            {loading ? (
              <ThreeDots
                visible={true}
                height="80"
                width="80"
                color="#8e008e"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                wrapperClass=""
              />
            ) : (
              // <p>Loading...</p>
              <table
                className="table table-hover js-basic-example dataTable table-custom table-striped m-b-0 c_list default_pageitem notification_table no-footer"
                id="notification_table"
                data-page-length="50"
                role="grid"
                aria-describedby="notification_table_info"
              >
                <thead className="thead-dark">
                  <tr role="row">
                    <th
                      className="sorting"
                      tabIndex="0"
                      aria-controls="notification_table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Course Name: activate to sort column ascending"
                    >
                      Weekday / Time
                    </th>
                    <th
                      className="sorting"
                      tabIndex="0"
                      aria-controls="notification_table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Type: activate to sort column ascending"
                    >
                      Monday
                    </th>
                    <th
                      className="sorting"
                      tabIndex="0"
                      aria-controls="notification_table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Periode: activate to sort column ascending"
                    >
                      Tuesday
                    </th>
                    <th
                      className="sorting"
                      tabIndex="0"
                      aria-controls="notification_table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Marks: activate to sort column ascending"
                    >
                      Wednesday
                    </th>
                    <th
                      className="sorting"
                      tabIndex="0"
                      aria-controls="notification_table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Grades: activate to sort column ascending"
                    >
                      Thursday
                    </th>
                    <th
                      className="sorting"
                      tabIndex="0"
                      aria-controls="notification_table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Grades: activate to sort column ascending"
                    >
                      Friday
                    </th>
                    <th
                      className="sorting"
                      tabIndex="0"
                      aria-controls="notification_table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Grades: activate to sort column ascending"
                    >
                      Saturday
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {schedule?.get_slot?.map((val) => {
                    return (
                      <tr key={val?.id}>
                        <td className="slotstym">
                          {val?.start_time}-{val?.end_time}
                        </td>
                        <td
                          colspan="6"
                          style={{
                            background: `${
                              val?.period_name ? "#ffa812" : "white"
                            }`,
                            fontWeight: "bold",
                          }}
                        >
                          {val?.period_name}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
