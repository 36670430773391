import React, { useEffect, useState } from "react";
import { Link, json, useParams } from "react-router-dom";
import Header from "../components/Header";
import Pagination from "../components/Pagination";
import "../css/style.css";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import GeneratePdf from "../components/GeneratePdf";
import { useMessages } from "../context/MessageContext";

const Exams = () => {
  const [quizes, setQuizes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showInstructionModal, setShowInstructionModal] = useState(false);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const apiUrl = process.env.REACT_APP_API_NODE_URL;
  const apiUrlMain = process.env.REACT_APP_API_MAIN_URL;
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const { studentId } = useParams();
  const { sutudentSub } = useParams();
  const [selectedField, setSelectedField] = useState("");
  const [addField, setAddFiled] = useState(false);
  const [classes, setClasses] = useState([]); // State for classes
  const [selectedPdf, setSelectedPdf] = useState(null);
  const { showSuccess, showError, clearMessages } = useMessages();
  const [imageVal, setImageVal] = useState([]);
  const [imageVal2, setImageVal2] = useState(null);
  const [imageFields, setImageFields] = useState([{ id: 1 }]);
  const sessionData = JSON.parse(localStorage.getItem("student"));
  const apacheUrl = process.env.REACT_APP_APACHE;
  const [originalData, setOriginalData] = useState([]);
  const [isAscending, setIsAscending] = useState(true); // State to toggle sorting orde
  const [sortKey, setSortKey] = useState("title"); // Default sorting key

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [studentId, sutudentSub]);

  const addImageField = () => {
    setImageFields([...imageFields, { id: imageFields.length + 1 }]); // Add new input field
  };

  const removeImageField = (id) => {
    setImageFields(imageFields.filter((field) => field.id !== id)); // Remove input field by id
  };

  const Loader = () => (
    <div className="loader">
      <div className="spinner"></div> {/* This can be any loader design */}
    </div>
  );

  const handleViewPdf = (pdfUrl) => {
    setSelectedPdf(pdfUrl);
  };
  // exam api
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const offset = (currentPage - 1) * limit;

      const language = localStorage.getItem("lang");

      const requestOptions = {
        method: "GET",
      };

      try {
        const response = await fetch(
          `${apiUrl}/quiz/getQuiz?school_id=${sessionData?.school_id}&subject_id=${studentId}&class_id=${sessionData?.class}&student_id=${sessionData?.id}&type=Exams&session_id=${sessionData?.session_id}&lang=${language}`,
          requestOptions
        );
        const result = await response.json();
        setQuizes(result);
        setOriginalData(result);
        setTotalPages(Math.ceil(result.total_records / limit));
      } catch (error) {
        console.error(error);
        setError(error.message || "Something went wrong");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [apiUrl, currentPage, limit, studentId]);

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    setCurrentPage(1);
    const filteredData = originalData.filter(
      (item) =>
        item.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.exam_period?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.exam_type?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.max_marks?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    console.log(filteredData, originalData);
    setQuizes(filteredData);
  };

  // classList
  useEffect(() => {
    const getClasses = async () => {
      const studentData = JSON.parse(localStorage.getItem("student"));
      const language = localStorage.getItem("lang");

      const requestOptions = {
        method: "GET",
      };
      try {
        const response = await fetch(
          `${apiUrlMain}/classlist.json?school_id=${studentData?.school_id}&lang=${language}`,
          requestOptions
        );
        const result = await response.json();
        console.log(result, "result");
        const filterClass = await result?.classes?.filter((v) => {
          return v?.id == studentData?.class;
        });
        setClasses(filterClass[0]); // Assuming your API returns an array of classes
      } catch (error) {
        console.error(error);
      }
    };

    getClasses();
  }, [apiUrlMain, quizes?.class_id]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const handleShowModal = (quiz) => {
    setSelectedQuiz(quiz);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedField("");
    setAddFiled(false);
    setShowModal(false);
    setSelectedQuiz(null);
  };

  const goBack = () => {
    window.history.back();
  };

  console.log("ss", selectedQuiz);
  const submitQuize = async () => {
    setLoading(true);
    const sessionData = await JSON.parse(localStorage.getItem("student"));
    const language = localStorage.getItem("lang");

    const authToken = "vWEMNrj5R8aKwZYYZoxcUMDWk5OnHf";
    const formData = new FormData();

    if (imageVal.length > 0) {
      imageVal?.map((val) => formData.append("file_name", val));
    } else if (setImageVal2 !== null) {
      formData.append("file_name", imageVal2);
    }
    formData.append("status", 1);
    formData.append("student_id", sessionData?.id);
    formData.append("exam_id", selectedQuiz.id);
    formData.append("file_type", selectedField);
    formData.append("lang", language);

    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: formData,
    };
    console.log("sub", selectedQuiz);
    try {
      const response = await fetch(
        `${apiUrl}/exam/submitExamUpdate/${selectedQuiz.submit_exams[0].id}`,
        requestOptions
      );
      const result = await response.json();
      if (response.ok) {
        showSuccess(`${result.message}: Exam Uploaded`);
        window.location.reload();
      } else {
        showError(result.message);
      }
      setImageVal("");

      handleCloseModal();
    } catch (error) {
      console.error(error);
      showError(error);
    } finally {
      setLoading(false);
      setTimeout(() => {
        clearMessages();
      }, 3000);
    }
  };

  const getImageFile = (e) => {
    setImageVal((prevState) => [...prevState, e.target.files[0]]);
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const handleCloseINstructionModal = () => {
    setShowInstructionModal(false);
  };

  const onSelectedField = (e) => {
    setSelectedField(e.target.value);
  };

  const toggleSortOrder = (key) => {
    if (sortKey === key) {
      // If the same key is clicked, toggle the sorting order
      setIsAscending(!isAscending);
    } else {
      // If a different key is clicked, set it as the sort key and reset to ascending
      setSortKey(key);
      setIsAscending(true);
    }
  };

  const sortedNotifications = [...quizes].sort((a, b) => {
    if (sortKey === "start_date" || sortKey === "end_date") {
      // Handle date comparison
      const dateA = moment(a[sortKey], "DD-MM-YYYY");
      const dateB = moment(b[sortKey], "DD-MM-YYYY");
      return isAscending ? dateA - dateB : dateB - dateA;
    }
    return isAscending
      ? (a[sortKey] || "").localeCompare(b[sortKey] || "")
      : (b[sortKey] || "").localeCompare(a[sortKey] || "");
  });

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="Exams" click={sidebarOpen} />
        <div className="card_board">
          <div className="content_card_title flex-view">
            Subjects &gt; {sutudentSub} &gt; Exams{" "}
            <button className="back-btn" onClick={goBack}>
              Back
            </button>
          </div>
          <div className="row clearfix" style={{ padding: "0px 20px" }}>
            <div className="col-lg-12">
              <div className="card">
                <div className="entries-and-search-container">
                  <div className="entries">
                    Show
                    <select
                      className="form-control"
                      value={limit}
                      onChange={handleLimitChange}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                    </select>
                    entries
                  </div>
                  <div className="entries">
                    Search:
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
                <div className="table-container">
                  <div className="table-responsive">
                    {loading ? (
                      <ThreeDots
                        visible={true}
                        height="80"
                        width="80"
                        color="#8e008e"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        wrapperClass=""
                      />
                    ) : (
                      // <p>Loading...</p>
                      <table
                        className="table table-hover js-basic-example dataTable table-custom table-striped m-b-0 c_list default_pageitem notification_table no-footer"
                        id="notification_table"
                        data-page-length="50"
                        role="grid"
                        aria-describedby="notification_table_info"
                      >
                        <thead className="thead-dark">
                          <tr role="row">
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Title: activate to sort column ascending"
                            >
                               <i
                                class="bi bi-arrow-down-up"
                                style={{ marginRight: "5px" }}
                                onClick={() => toggleSortOrder("title")}
                              ></i>
                              Title
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Exam Type: activate to sort column ascending"
                            >
                               <i
                                class="bi bi-arrow-down-up"
                                style={{ marginRight: "5px" }}
                                onClick={() => toggleSortOrder("exam_type")}
                              ></i>
                              Exams Type
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Periode: activate to sort column ascending"
                            >
                               <i
                                class="bi bi-arrow-down-up"
                                style={{ marginRight: "5px" }}
                                onClick={() => toggleSortOrder("exam_period")}
                              ></i>
                              Periode
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Max Mark: activate to sort column ascending"
                            >
                               <i
                                class="bi bi-arrow-down-up"
                                style={{ marginRight: "5px" }}
                                onClick={() => toggleSortOrder("max_marks")}
                              ></i>
                              Max Marks
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Class: activate to sort column ascending"
                            >
                               <i
                                class="bi bi-arrow-down-up"
                                style={{ marginRight: "5px" }}
                                onClick={() => toggleSortOrder("c_name")}
                              ></i>
                              Class
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Start Date: activate to sort column ascending"
                            >
                               <i
                                class="bi bi-arrow-down-up"
                                style={{ marginRight: "5px" }}
                                onClick={() => toggleSortOrder("start_date")}
                              ></i>
                              Start Date
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="End Date: activate to sort column ascending"
                            >
                              <i
                                class="bi bi-arrow-down-up"
                                style={{ marginRight: "5px" }}
                                onClick={() => toggleSortOrder("end_date")}
                              ></i>
                              End Date
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="View Exam: activate to sort column ascending"
                            >
                              View Exam
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Status: activate to sort column ascending"
                            >
                              Status
                            </th>

                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Upload Answers: activate to sort column ascending"
                            >
                              Upload Answers
                            </th>
                          </tr>
                        </thead>
                        <tbody id="notificationbody">
                          {sortedNotifications?.map((quiz) => (
                            <tr role="row" className="odd" key={quiz?.id}>
                              <td>{quiz?.title}</td>
                              <td>{quiz?.exam_type}</td>
                              <td>{quiz?.exam_period}</td>
                              <td>{quiz?.max_marks}</td>
                              <td>
                                {classes?.c_name}-{classes?.c_section}(
                                {classes?.school_sections})
                              </td>
                              <td>
                                {moment(
                                  quiz?.start_date,
                                  "DD-MM-YYYY HH:mm"
                                ).format("MMM DD, YYYY hh:mm A")}
                              </td>
                              <td>
                                {moment(
                                  quiz?.end_date,
                                  "DD-MM-YYYY HH:mm"
                                ).format("MMM DD, YYYY hh:mm A")}
                              </td>
                              <td>
                                {quiz?.exam_format === "custom" ? (
                                  <Link
                                    to={`/dashboard/subject/exams/view_exam/${quiz?.submit_exams[0].id}/${quiz?.id}`}
                                    style={{
                                      cursor: "pointer",
                                      background: "#8e008e",
                                      color: "white",
                                      border: "none",
                                    }}
                                  >
                                    View Exam
                                  </Link>
                                ) : quiz?.submit_exams[0].status == 1 &&
                                  quiz?.submit_exams[0].submit_answersheet !=
                                    null ? (
                                  <Link
                                    to="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      window.open(
                                        `${apacheUrl}?studid=${sessionData?.id}&classId=${sessionData?.class}&p=quiz&subId=${studentId}&submittedQuizmode=31507&submittedQuizHash=${quiz?.file_name}&returnUrl=L3NjaG9vbC9xdWl6`,
                                        "_blank",
                                        "noopener,noreferrer"
                                      );
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      background: "#8e008e",
                                      color: "white",
                                      border: "none",
                                    }}
                                    className="instruction-btn"
                                  >
                                    <i className="bi bi-download"></i> View Exam
                                  </Link>
                                ) : (
                                  <Link
                                    to={`/dashboard/subject/exams/view_Exam/info/${studentId}/${quiz?.id}`}
                                    style={{
                                      cursor: "pointer",
                                      background: "#8e008e",
                                      color: "white",
                                      border: "none",
                                    }}
                                    className="instruction-btn"
                                  >
                                    View Exam
                                  </Link>
                                )}
                              </td>
                              <td>
                                {quiz?.submit_exams[0].status == 1
                                  ? "Submitted"
                                  : "Not Submitted"}
                              </td>

                              <td>
                                {quiz?.submit_exams[0].status == 1 ? (
                                  quiz?.submit_exams[0].submit_answersheet !=
                                  null ? (
                                    <Link
                                      to={`/dashboard/pdf/${sutudentSub}/${studentId}/${quiz?.id}`}
                                      style={{
                                        cursor: "pointer",
                                        background: "#8e008e",
                                        color: "white",
                                        border: "none",
                                      }}
                                      className="bi bi-file-earmark-text"
                                      target="_blank"
                                    ></Link>
                                  ) : (
                                    <Link
                                      to={`/dashboard/pdf/${sutudentSub}/${studentId}/${quiz?.id}`}
                                      style={{
                                        cursor: "pointer",
                                        background: "#8e008e",
                                        color: "white",
                                        border: "none",
                                      }}
                                      className="bi bi-file-earmark-text"
                                      target="_blank"
                                    ></Link>
                                  )
                                ) : (
                                  <button
                                    onClick={() => handleShowModal(quiz)}
                                    className="btn btn-sm btn-outline-secondary"
                                    style={{
                                      cursor: "pointer",
                                      background: "#8e008e",
                                      color: "white",
                                      border: "none",
                                    }}
                                  >
                                    <i className="bi bi-upload"></i>
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
                <div className="pagination-container">
                  <p>
                    Show 1 to {limit} of {quizes?.length} entries
                  </p>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Custom Modal */}
      <div
        className={`student-Modal-container`}
        style={{ visibility: `${showModal ? "visible" : "hidden"}` }}
      >
        <div
          className={`modal-box ${showModal ? "transformOn" : "transformOff"}`}
          style={{ maxWidth: "600px", minWidth: "250px" }}
        >
          <div className="custom-modal-title">
            Upload Exam
            <i
              className="bi bi-x-lg"
              onClick={handleCloseModal}
              style={{ cursor: "pointer" }}
            ></i>
          </div>
          {loading && <Loader />}
          {!loading && selectedQuiz && (
            <>
              <h5>Upload Exam </h5>
              <select onChange={(e) => onSelectedField(e)}>
                <option>--Select Type--</option>
                <option value="pdf">Pdf</option>
                <option value="images">Image</option>
              </select>

              {selectedField === "images" && (
                <>
                  {imageFields.map((field) => (
                    <div
                      key={field.id}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <input
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        className="custom-modal-file"
                        onChange={(e) => getImageFile(e, field.id)}
                      />
                      <i
                        className="bi bi-dash"
                        style={{ cursor: "pointer" }}
                        onClick={() => removeImageField(field.id)}
                      ></i>
                    </div>
                  ))}
                  <i
                    className="bi bi-plus"
                    style={{ cursor: "pointer" }}
                    onClick={addImageField}
                  ></i>
                </>
              )}

              {selectedField === "pdf" && (
                <input
                  type="file"
                  accept="application/pdf"
                  className="custom-modal-file"
                  onChange={(e) => setImageVal2(e.target.files[0])}
                />
              )}

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button className="back-btn" onClick={submitQuize}>
                  Upload Exam
                </button>
                <span style={{ margin: "5px" }}></span>
                <button className="back-btn" onClick={handleCloseModal}>
                  Close
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Exams;
