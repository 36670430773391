import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import CryptoJS from "crypto-js";

const DayAttendance = () => {
  const [loading, setLoading] = useState(false);
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [events, setEvents] = useState([]);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [currentDate, setCurrentDate] = useState("");

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const goBack = () => {
    window.history.back();
  };

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      const sessionData = await JSON.parse(localStorage.getItem("student"));
      const language = localStorage.getItem('lang');
      const numberString = sessionData?.id?.toString();
      const md5Hash = CryptoJS.MD5(numberString).toString();

      const formData = new FormData();
      formData.append("student_id", md5Hash);
      formData.append('lang', language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      try {
        const response = await fetch(
          `${apiUrl}/studentAttendence.json`,
          requestOptions
        );
        const data = await response.json();
        console.log("API Response Data:", data);

        if (data.attendence && Array.isArray(data.attendence)) {
          const formattedEvents = data.attendence.map((event) => {
            console.log("Event Data:", event); // Log individual event data
            return {
              title: event.title, // Use 'title' from API response
              date: event.date,   // Ensure 'date' is in YYYY-MM-DD format
            };
          });
          console.log("Formatted Events:", formattedEvents); // Log formatted events
          setEvents(formattedEvents);
        } else {
          console.error("Unexpected data structure:", data);
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [apiUrl]);

  useEffect(() => {
    // Format current date to YYYY-MM-DD
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setCurrentDate(formattedDate);
  }, []);

  const handleEventDidMount = (info) => {
    console.log("Event Did Mount Info:", info.event); // Log event info
    if (info.event.title === "Present") {
      info.el.style.backgroundColor = "green";
      info.el.style.color = "white";
    } else if (info.event.title === "Absent") {
      info.el.style.backgroundColor = "red";
      info.el.style.color = "white";
    }
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}>
        <ContentTitle name="Attendance" click={sidebarOpen} />
        <div className="card_board">
          <div className="content_card_title flex-view">
            StudentProfile - Attendance - School Attendance
            <button className="back-btn" onClick={goBack}>
              Back
            </button>
          </div>
          <div className="form-container">
            <div style={{ width: "50%", margin: "0 auto", marginBottom: "100px" }}>
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                events={events}
                editable={true}
                selectable={true}
                dateClick={(info) => alert(`Date clicked: ${info.dateStr}`)}
                eventDidMount={handleEventDidMount}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DayAttendance;
