import React, { useEffect, useRef, useState } from "react";
import schoolLogo from "../images/schoolLogo.png";
import { useParams } from "react-router-dom";
import html2pdf from "html2pdf.js";
import { ThreeDots } from "react-loader-spinner";

const GeneratePdfInvoice = () => {
  const { orderID } = useParams();
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [invoiceData, setInvoiceData] = useState([]); // Original fetched data
  const [error, setError] = useState(null);
  const pdfInvoiceRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [download, setDownload] = useState(false);
  const [sum_quantity, setSum_Quantity] = useState(0);
  const [sum_price, setSum_Price] = useState(0);
  const [sum_total, setSum_Total] = useState(0);

  useEffect(() => {
    const fetchPdfData = async () => {
      setLoading(true);
      const sessionData = await JSON.parse(localStorage.getItem("student"));
      const language = localStorage.getItem("lang");

      const formData = new FormData();
      formData.append("order_no", orderID);
      formData.append("student_id", sessionData?.id);
      formData.append("lang", language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      try {
        const response = await fetch(
          `${apiUrl}/orderinfo.json`,
          requestOptions
        );
        let result = await response.json();
        result.data = result.data.map((item) => ({
          ...item,
          food_item: {
            ...item.food_item,
            food_img: `https://you-me-globaleducation.org/school/c_food/${item?.food_item?.food_img}`,
          },
        }));

        console.log("Result", result);
        setInvoiceData(result);

        // quantity
        const sumQuant = await result?.data.reduce(
          (a, v) => (a = a + v.quantity),
          0
        );
        setSum_Quantity(sumQuant);

        // price
        const sumPri = await result?.data.reduce(
          (a, v) => (a = a + v.food_amount),
          0
        );
        setSum_Price(sumPri);

        // total
        const sumTot = await result?.data.reduce(
          (a, v) => (a = a + v.total_amount),
          0
        );
        setSum_Total(sumTot);
      } catch (error) {
        setError(error.message || "Something went wrong");
      } finally {
        setLoading(false);
      }
    };

    fetchPdfData();
  }, [apiUrl, orderID]);

  useEffect(() => {
    if (!loading && !download && invoiceData?.data?.length > 0) {
      setTimeout(generateAndOpenPdf, 5000); // Shorter timeout as images are preloaded
      setDownload(true);
    }
  }, [loading, invoiceData?.data, download]);

  const generateAndOpenPdf = async () => {
    const element = pdfInvoiceRef.current;
    await html2pdf(element, {
      hmargin: 0,
      vmargin: 20,
      filename: `invoice_${orderID}.pdf`,
      image: { quality: 1 },
      html2canvas: {
        logging: true, // Useful for debugging
        dpi: 300,
        letterRendering: true,
        useCORS: true,
        allowTaint: false,
        scale: 2,
      },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    });
    setTimeout(window.history.back(), 2000);
  };

  console.log(sum_quantity, "quantity");

  return loading ? (
    <ThreeDots
      visible={true}
      height="80"
      width="80"
      color="#8e008e"
      radius="9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    />
  ) : (
    <div className="invoice-pdf-wrapper" ref={pdfInvoiceRef}>
      <div className="invoice-pdf-container">
        <h2>Receipt</h2>
        <div className="student-detail-container">
          <div className="profile-img">
            <img src={schoolLogo} alt="School Logo" />
            <span>Nancy School - 25 May</span>
          </div>
          <div className="student-detail">
            <div className="text-container">
              <p>
                Student Name:{" "}
                <span>{invoiceData?.studentinfo?.["Student Name"]}</span>
              </p>
              <p>
                Student No:{" "}
                <span>{invoiceData?.studentinfo?.["Student No."]}</span>
              </p>
              <p>
                Order No: <span>{invoiceData?.studentinfo?.["Order No."]}</span>
              </p>
              <p>
                Date/Time :{" "}
                <span>{invoiceData?.studentinfo?.["Date/Time"]}</span>
              </p>
              <p>
                Class: <span>{invoiceData?.studentinfo?.Class}</span>
              </p>
              <p>
                School: <span>{invoiceData?.studentinfo?.School}</span>
              </p>
            </div>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Vendor Company</th>
              <th>Food Image</th>
              <th>Food Name</th>
              <th>Quantity</th>
              <th>Food Price</th>
              <th>Total Amount</th>
              <th>Order Status</th>
            </tr>
          </thead>
          <tbody>
            {invoiceData?.data?.map((data) => {
              return (
                <>
                  <tr role="row" className="odd" key={data?.id}>
                    <td className="width45 sorting_1">
                      {data?.canteen_vendor?.vendor_company}
                    </td>
                    <td>
                      <img
                        src={data?.food_item?.food_img}
                        alt="food-image"
                        style={{ height: "50px" }}
                      />
                    </td>
                    <td>{data?.food_item?.food_name}</td>
                    <td>{data?.quantity}</td>
                    <td>${data?.total_amount}</td>
                    <td>${data?.food_amount}</td>
                    <td>
                      {data?.order_status === 2 && (
                        <button
                          className="contact-btn"
                          style={{
                            cursor: "pointer",
                            backgroundColor: "red",
                            border: "unset",
                            color: "white",
                          }}
                        >
                          Cancelled
                        </button>
                      )}
                      {data?.order_status === 1 && (
                        <button
                          className="contact-btn"
                          style={{
                            cursor: "pointer",
                            backgroundColor: "green",
                            border: "unset",
                            color: "white",
                          }}
                        >
                          Delivered
                        </button>
                      )}
                      {data?.order_status === 4 && (
                        <button
                          className="contact-btn"
                          style={{
                            cursor: "pointer",
                            backgroundColor: "orange",
                            border: "unset",
                            color: "white",
                          }}
                        >
                          Pending
                        </button>
                      )}
                    </td>
                  </tr>
                </>
              );
            })}
            <tr>
              <td colSpan="3">Total</td>
              <td>{sum_quantity}</td>
              <td>${sum_price}</td>
              <td>${sum_total}</td>
              <td></td>
            </tr>
          </tbody>
        </table>

        {invoiceData?.remark}
      </div>
    </div>
  );
};

export default GeneratePdfInvoice;
