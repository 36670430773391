import React, { useEffect, useState } from "react";
import "../css/style.css";
import Header from "../components/Header";
import { useMessages } from "../context/MessageContext";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import { Link, useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

const Kindergarten = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [communityFilter, setCommunityFilter] = useState("newest");
  const [titleFilter, setTitleFilter] = useState("");
  const [dropbox, setDropbox] = useState([]);
  const [loading, setLoading] = useState(false);
  const [classes, setClasses] = useState([]);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const { showSuccess, showError, clearMessages } = useMessages();
  const studentData = JSON.parse(localStorage.getItem("student"));
  const [classFilter, setClassFilter] = useState("");
  const [title, setTitle] = useState([]);
  const SERVERIMGURL = "https://you-me-globaleducation.org/school/img";
  const { name } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [name]);

  const handleClassChange = (event) => {
    setClassFilter(event.target.value);
  };

  const handleCommunityChange = (event) => {
    setCommunityFilter(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitleFilter(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const language = localStorage.getItem("lang");
      const formData = new FormData();
      formData.append("community", name);
      formData.append("title", titleFilter);
      formData.append("filter", communityFilter);
      formData.append("classes", classFilter);
      formData.append("lang", language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };
      try {
        const response = await fetch(
          `${apiUrl}/youmeknowledgecommunityactive.json`,
          requestOptions
        );
        const result = await response.json();
        setDropbox(result?.know_details);
        setTitle(result?.title_list);
        setClasses(result?.cls_details);
        showSuccess(result.message);
      } catch (error) {
        console.error(error);
        showError(error);
      } finally {
        setLoading(false);
        setTimeout(() => {
          clearMessages();
        }, 3000);
      }
    };
    fetchData();
  }, [apiUrl, classFilter, titleFilter, communityFilter]);

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const clearInput = () => {
    setClassFilter("");
    setCommunityFilter("newest");
  };

  const clearInput2 = () => {
    setTitleFilter("");
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="You-Me Academy" click={sidebarOpen}  sidebarToggle={sidebarToggle}/>
        <div className="card_board">
          <div className="content_card_title">
            You-Me Knowledge Center &gt;{" "}
            {name == "highscl"
              ? "High School"
              : name.charAt(0).toUpperCase() + name.slice(1)}
            <div className="selectboxes">
              {classFilter !== "" ||
                (communityFilter !== "newest" && (
                  <>
                    <button className="back-btn" onClick={clearInput}>
                      Clear
                    </button>
                    <span style={{ marginRight: "10px" }}></span>{" "}
                  </>
                ))}
              <select
                className="form-control subj_s select2-hidden-accessible"
                id="subjfilter"
                onChange={(e) => handleClassChange(e)}
                value={classFilter}
              >
                <option value="">Choose Class</option>
                {classes?.map((classVal) => (
                  <option key={classVal?.id} value={classVal?.id}>
                    {classVal?.c_name}
                  </option>
                ))}
              </select>
              <select
                className="form-control community_filter select2-hidden-accessible"
                id="comm_filter"
                onChange={handleCommunityChange}
                value={communityFilter}
              >
                <option value="newest">Newest</option>
                <option value="pdf">PDF</option>
                <option value="video">Video</option>
                <option value="audio">Audio</option>
              </select>
              <button className="back-btn" onClick={goBack}>
                Back
              </button>
            </div>
          </div>
          <div className="form-container">
            <div className="drobbox-body">
              <select
                className="form-control js-states chosetitle select2-hidden-accessible"
                id="title_filter"
                onChange={handleTitleChange}
                value={titleFilter}
              >
                <option value="">Choose Title</option>
                {title?.map((t) => (
                  <option key={t?.max_id} value={t?.title}>
                    {t?.title}
                  </option>
                ))}
              </select>
              {titleFilter !== "" && (
                <button className="back-btn" onClick={clearInput2}>
                  Clear
                </button>
              )}

              {loading ? (
                <ThreeDots
                  visible={true}
                  height="80"
                  width="80"
                  color="#8e008e"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  wrapperClass=""
                />
              ) : (
                // <p>Loading...</p>
                <div className="data-body">
                  <div className="data-body-row" id="viewcommunity">
                    {dropbox?.map((drop) => {
                      console.log(drop.id, "idddddddddddd");
                      return (
                        <div
                          className="col-sm-2 col_img"
                          key={drop.id}
                          style={{ marginBottom: "20px" }}
                        >
                          <Link
                            to={`/dashboard/student-knowledge/community/view/${drop.id}`}
                            className="viewknow"
                          >
                            <img
                              src={`${SERVERIMGURL}/${drop?.image}`}
                              alt="Dropbox Icon"
                            />
                            <div className="set_icon">
                              <i
                                className={`${
                                  drop?.file_type === "pdf"
                                    ? "bi bi-file-pdf"
                                    : drop?.file_type === "video"
                                    ? " bi bi-camera-video"
                                    : drop?.file_type === "audio"
                                    ? "bi bi-headphones"
                                    : "bi bi-filetype-doc"
                                }`}
                              ></i>
                            </div>
                          </Link>
                          <p
                            className="title"
                            style={{ color: "#000", margin: "0" }}
                          >
                            <b>Titre</b>: {drop?.title}
                          </p>
                          <p
                            className="title"
                            style={{ color: "#000", margin: "0" }}
                          >
                            <b>Classes</b>: {drop?.classname}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {/* {!dropbox?.length ? <h3 style={{ color: "gray", margin: "0" }}>Data Not Found</h3> : ""} */}
              <form
                method="post"
                acceptCharset="utf-8"
                action="/school/Dropbox/"
                style={{ display: "none" }}
              >
                <div>
                  <input type="hidden" name="_method" value="POST" />
                  <input
                    type="hidden"
                    name="_csrfToken"
                    autoComplete="off"
                    value="fd82b978dfb069d344e73b0d4e9dca020ee9d23fbca43988689186f03e216fc58422d41d5d040c943c9761c1bcb0be38cb915d43025ce94bebffabf8685a6482"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kindergarten;
