import React, { useEffect, useState } from "react";
import "../css/style.css";
import Header from "../components/Header";
import { useMessages } from "../context/MessageContext";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import { Link, useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import dummyImage from "../images/logo.png";
import { useNavigate } from "react-router-dom";

const TutoringContent = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [communityFilter, setCommunityFilter] = useState("newest");
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [internship, setInternship] = useState([]);
  const SERVERIMGURL = "https://you-me-globaleducation.org/school/img";
  const { subID, tID, cID } = useParams();
  const navigate = useNavigate();

  const handleCommunityChange = (event) => {
    setCommunityFilter(event.target.value);
  };

  useEffect(() => {
    const getInternship = async () => {
      setLoading(true);
      const language = localStorage.getItem("lang");
      const studentData = JSON.parse(localStorage.getItem("student"));
      const formData = new FormData();
      formData.append("tid", tID);
      formData.append("school_id", studentData?.school_id);
      formData.append("subjectid", subID);
      formData.append("classid", cID);
      formData.append("student_id", studentData?.id);
      formData.append("filter_type", communityFilter);
      formData.append("lang", language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };
      try {
        const response = await fetch(
          `${apiUrl}/tutoringcontent.json`,
          requestOptions
        );
        const result = await response.json();
        setInternship(result?.content_details);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getInternship();
  }, [apiUrl, communityFilter]);

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="Tutoring Center" click={sidebarOpen} />
        <div className="card_board">
          <div className="content_card_title">
            Tutoring Content
            <div className="selectboxes">
              <select
                className="form-control community_filter select2-hidden-accessible"
                id="comm_filter"
                onChange={handleCommunityChange}
                value={communityFilter}
              >
                <option value="newest">Newest</option>
                <option value="pdf">PDF</option>
                <option value="video">Video</option>
                <option value="word">Word Document</option>
                <option value="audio">Audio</option>
              </select>
              <button className="contact-us-btn" onClick={()=> navigate(`/dashboard/tutoring_center/class/${subID}/${tID}`)}>
                Online Tutoring Meeting
              </button>
              <span
                style={{ marginRight: "10px", marginBottom: "10px" }}
              ></span>
              <button className="back-btn" onClick={goBack}>
                Back
              </button>
            </div>
          </div>
          <div className="form-container">
            <div className="drobbox-body">
              {loading ? (
                <ThreeDots
                  visible={true}
                  height="80"
                  width="80"
                  color="#8e008e"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  wrapperClass=""
                />
              ) : (
                // <p>Loading...</p>
                <div className="data-body">
                  <div className="row col-sm-12" id="viewcommunity">
                    {internship?.map((intern) => {
                      return (
                        <div
                          className="col-sm-2 col_img"
                          key={intern.id}
                          style={{ marginBottom: "20px" }}
                        >
                          <Link
                            to={`/dashboard/tutoring_center/content/view/${intern.id}/${tID}`}
                            className="viewknow"
                          >
                            {intern?.image !== "" ? (
                              <img
                                src={`${SERVERIMGURL}/${intern?.image}`}
                                alt="Dropbox Icon"
                              />
                            ) : (
                              <img src={dummyImage} alt="Dropbox Icon" />
                            )}
                            <div className="set_icon">
                              <i
                                className={`${
                                  intern?.file_type === "pdf"
                                    ? "bi bi-file-pdf"
                                    : intern?.file_type === "video"
                                    ? " bi bi-camera-video"
                                    : intern?.file_type === "audio"
                                    ? "bi bi-headphones"
                                    : "bi bi-filetype-doc"
                                }`}
                              ></i>
                            </div>
                          </Link>
                          <p className="title" style={{ color: "#000" }}>
                            <b>Title</b>: {intern.title}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {/* {!internship?.length ? <h3 style={{ color: "gray", margin: "0" }}>Data Not Found</h3> : ""} */}
              <form
                method="post"
                acceptCharset="utf-8"
                action="/school/Dropbox/"
                style={{ display: "none" }}
              >
                <div>
                  <input type="hidden" name="_method" value="POST" />
                  <input
                    type="hidden"
                    name="_csrfToken"
                    autoComplete="off"
                    value="fd82b978dfb069d344e73b0d4e9dca020ee9d23fbca43988689186f03e216fc58422d41d5d040c943c9761c1bcb0be38cb915d43025ce94bebffabf8685a6482"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutoringContent;
