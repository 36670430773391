import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import ContentTitle from "../components/ContentTitle";
import { ThreeDots } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const CanteenFeatureVendor = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [loading, setLoading] = useState(false);
  const { date, time } = useParams();
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [featureCanteen, setFeatureCanteen] = useState([]);

  const navigate = useNavigate();

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const goBack = () => {
    window.history.back();
  };

  // Feature canteen order detail
  useEffect(() => {
    const fetchTime = async () => {
      setLoading(true);
      const sessionData = await JSON.parse(localStorage.getItem("student"));
      const language = localStorage.getItem("lang");
      const formData = new FormData();
      formData.append("school_id", sessionData?.school_id);
      formData.append("class_id", sessionData?.class);
      formData.append("seldate", date);
      formData.append("seltime", time);
      formData.append("lang", language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      try {
        const response = await fetch(
          `${apiUrl}/vendorfeature.json`,
          requestOptions
        );
        const data = await response.json();
        setFeatureCanteen(data?.vendor_details);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTime();
  }, [apiUrl, date, time]);

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />

      <div className={`main containerFull`}>
        <ContentTitle name="Canteen" click={sidebarOpen} />
        <div className="card_board">
          {loading ? (
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#8e008e"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              wrapperClass=""
            />
          ) : (
            <>
              <div className="content_card_title">
                <h2
                  style={{
                    color: "rgb(75, 73, 73)",
                    fontWeight: "500",
                    margin: "0",
                  }}
                >
                  Feature Vendor's for Date: {date} & Timings: {time}
                </h2>

                <div className="selectboxes">
                  <button
                    className="contact-us-btn"
                    onClick={()=>navigate("/dashboard")}
                  >
                    Back To Dashboard
                  </button>
                  <span
                    style={{ marginRight: "10px", marginBottom: "10px" }}
                  ></span>
                  <button className="back-btn" onClick={goBack}>
                    Back
                  </button>
                </div>
              </div>
              <div className="cantee-boxes-container">
                {featureCanteen?.map((val, index) => {
                  return (
                    <div className="canteen-box" key={index}>
                      <img
                        src={`https://you-me-globaleducation.org/school/canteen/${val?.canteen_vendor?.logo}`}
                        alt="box-image"
                      />
                      <div className="combo-container">
                        <h2>
                          {val?.canteen_vendor?.vendor_company.toUpperCase()}
                        </h2>
                        <div className="btn-container">
                          <button onClick={()=> navigate(`/dashboard/canteen/vendoritems/${date}/${time}/${val?.id}`)}>View Details</button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CanteenFeatureVendor;
