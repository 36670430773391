import React, { useEffect, useState } from 'react';
import '../css/style.css'; // Import your CSS file
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { Link } from 'react-router-dom';
import ContentTitle from "../components/ContentTitle"

const StateExam = () => {
    const [sidebarToggle, setSidebarToggle] = useState(true);
    const apiUrl = process.env.REACT_APP_API_MAIN_URL;
    const [loading, setLoading] = useState(false);
    const [stateExam, setStateExam] = useState([])

    useEffect(() => {
        const getLibrary = async () => {
            setLoading(true);
            const language = localStorage.getItem('lang');

            const formData = new FormData();
            formData.append("School", 1);
            formData.append('lang', language);

            const requestOptions = {
                method: "POST",
                body: formData,
            };
            try {
                const response = await fetch(`${apiUrl}/stateexam.json`, requestOptions);
                const result = await response.json();
                setStateExam(result);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        getLibrary();
    }, [apiUrl]);


    const sidebarOpen = () => {
        setSidebarToggle(!sidebarToggle);
    }

    const goBack = () => {
        window.history.back();
    };

    return (
        <div className="dashboard">
            <Header click={sidebarOpen} />
            <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
            <div className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}>
                <ContentTitle name="Student Dashboard" click={sidebarOpen} />
                <div className='card_board'>
                    <div className='content_card_title flex-view'>State exam<button className='back-btn' onClick={goBack}>Back</button></div>
                    <div className='form-container'>
                        <div className="options">
                            {stateExam?.data?.map((val) => {
                                return <Link to={`/dashboard/state-exam/${val?.url}`}>
                                    <button className="option" data-target="grades">{val?.title}</button>
                                </Link>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StateExam;
