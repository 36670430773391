import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import ContentTitle from "../components/ContentTitle";

const ReportCard = () => {
  const { apiUrlLink } = useParams();
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const studentData = JSON.parse(localStorage.getItem("student"));
  const [classData, setClassData] = useState("");
  const [sidebarToggle, setSidebarToggle] = useState(true);

  useEffect(() => {
    const getClasses = async () => {
      const language = localStorage.getItem("lang");
      const requestOptions = {
        method: "GET",
      };
      try {
        const response = await fetch(
          `${apiUrl}/${apiUrlLink}?classid=${studentData?.class}&studentid=${studentData?.id}&lang=${language}`,
          requestOptions
        );
        console.log(response?.url, "response");
        setClassData(response?.url);
      } catch (error) {
        console.error(error);
      }
    };

    getClasses();
  }, [apiUrl, apiUrlLink, studentData?.class, studentData?.id]);

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <div className={`main containerFull`}>
        <ContentTitle name="Student Dashboard" click={sidebarOpen} />
        <div
          className=""
          style={{
            overflow: "auto",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <div className="content_card_title flex-view">
            Report Card
            <button className="back-btn" onClick={goBack}>
              Back
            </button>
          </div>
          <div className="reportCard-iframe-container">
            <iframe src={classData} title="iframe link"></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportCard;
